import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {GestionService} from '../../services/gestion.service';
import {SessionService} from '../../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class DossierMembreResolver implements Resolve<boolean> {
  constructor(
    public gestionServ: GestionService,
    public sessionServ: SessionService
  ) {
  
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    
    return from(this.gestionServ.getDossierMembre(this.sessionServ.idVisiteur))
  }
  
}
