<app-page-std [pathRetour]="['/']">
    <app-label-component label="page-formulaire"></app-label-component>
    <div style="border: solid #0a0a25 3px; border-radius: 2rem; padding: 1rem; text-align: left">
        <div style="text-align: center"> <h1>AUTO-ÉVALUATION COVID-19</h1></div>
        <p>Avant de se présenter à une activité de hockey mineur, chaque joueur, parent, entraîneur et
        intervenant doit effectuer son auto-évaluation, ainsi que toute autre personne qui les accompagne</p>
        <div class="question grid col-12">
            <div class="col-12 md:col-10">
                <div class="titre indent">1- PRÉSENTEZ-VOUS UN DES SYMPTÔMES DE LA COVID-19 SUIVANTS:</div>
                <ul>
                    <li>Difﬁculté à respirer, essoufﬂement ou une sensation d’étouffement</li>
                    <li>Difﬁcultés respiratoires importantes même au repos tel un essoufﬂement qui vous empêche
                        de parler</li>
                    <li>Fièvre (température buccale de 38,1°C (100,6°F) et plus)</li>
                    <li>Perte soudaine de l’odorat sans congestion nasale, avec ou sans perte de goût</li>
                    <li>Toux récente ou aggravation d’une toux chronique</li>
                    <li>Mal de gorge</li>
                </ul>
            </div>
            <div class="col-12 md:col-2 reponses">
                <div style="text-align: center">
                    <div class="titre">OUI</div>
                    <p-radioButton name="q1" [value]="true" [(ngModel)]="rep1"></p-radioButton>
                </div>
                <div style="text-align: center">
                    <div class="titre">NON</div>
                    <p-radioButton name="q1" [value]="false" [(ngModel)]="rep1"></p-radioButton>
                </div>
            </div>
        </div>
        <hr>

        <div class="question grid col-12">
            <div class="col-12 md:col-10">
                <div class="titre indent">2- PRÉSENTEZ-VOUS AU MOINS DEUX DES SYMPTÔMES SUIVANTS:</div>
                <div style="font-size: .7rem; font-style: italic">
                    Les symptômes peuvent être légers ou plus sévères, comme ceux associés à la pneumonie.
                </div>
                <ul>
                    <li>Maux de ventre</li>
                    <li>Nausées (maux de cœur) ou vomissements</li>
                    <li>Diarrhées</li>
                    <li>Grande fatigue</li>
                    <li>Perte d’appétit importante</li>
                    <li>Douleurs musculaires généralisées (non liées à un effort physique)</li>
                    <li>Mal de tête</li>
                </ul>
            </div>
            <div class="col-12 md:col-2 reponses">
                <div style="text-align: center">
                    <div class="titre">OUI</div>
                    <p-radioButton name="q2" [value]="true" [(ngModel)]="rep2"></p-radioButton>
                </div>
                <div style="text-align: center">
                    <div class="titre">NON</div>
                    <p-radioButton name="q2" [value]="false" [(ngModel)]="rep2"></p-radioButton>
                </div>
            </div>
        </div>
        <hr>


        <div class="question grid col-12">
            <div class="col-12 md:col-10">
                <div class="titre indent">3- AVEZ-VOUS VOYAGÉ À L'ÉTRANGER (Y COMPRIS AUX ÉTATS-UNIS)
                AU COURS DES 14 DERNIERS JOURS</div>

            </div>
            <div class="col-12 md:col-2 reponses">
                <div style="text-align: center">
                    <div class="titre">OUI</div>
                    <p-radioButton name="q3" [value]="true" [(ngModel)]="rep3"></p-radioButton>
                </div>
                <div style="text-align: center">
                    <div class="titre">NON</div>
                    <p-radioButton name="q3" [value]="false" [(ngModel)]="rep3"></p-radioButton>
                </div>
            </div>
        </div>

        <hr>

        <div class="question grid col-12">
            <div class="col-12 md:col-10">
                <div class="titre indent">4- UNE AUTORITÉ COMPÉTENTE VOUS A-T-ELLE PRESCRIT DE RESTER EN ISOLEMENT
                    SUITE À UN CONTACT SURVENU AU COURS DES 14 DERNIERS JOURS?
                </div>

            </div>
            <div class="col-12 md:col-2 reponses">
                <div style="text-align: center">
                    <div class="titre">OUI</div>
                    <div>
                        <p-radioButton name="q4" [value]="true" [(ngModel)]="rep4"></p-radioButton>
                    </div>
                    <div style="clear: both"></div>
                </div>
                <div style="text-align: center">
                    <div class="titre">NON</div>
                    <div style="width: 100%">
                        <p-radioButton name="q4" [value]="false" [(ngModel)]="rep4"></p-radioButton>
                    </div>
                </div>
            </div>
        </div>

        <div style="clear: both; text-align: center; margin-top: .5rem; margin-bottom: 1rem">
            <a (click)="setResults(false, false, false, false)">
                J'ai bien considéré chaque question, et je réponds NON à toutes
            </a>
        </div>
        <div >
            <p>
                En répondant OUI à une des questions, vous justifiez l'absence de la personne concernée
                aux activités de la ligue par précaution, dans un souci de protéger la santé des
                autres participants.
            </p>


            <div class="text-left m-2 p-4 shadow-5 border-2 border-round border-pink-500 bg-yellow-200 font-bold" [class.hidden]="!(rep1 || rep2 || rep3 || rep4)">

                <div>
                    COMME MESURE PRÉVENTIVE, VEUILLEZ...
                    <ul>
                        <li>Ne pas vous présenter à l'aréna</li>
                        <li>Demeurer à la maison et communiquer avec le 1-877-644-4545</li>
                    </ul>
                </div>
            </div>
            <p>
                L'absence de la personne concernée n'aura pas à être autrement justifiée,
                et elle ne sera pénalisée d'aucune façon. Au contraire, nous la remercions
                de penser aux autres!
            </p>
        </div>

        <div style="clear: both; text-align: center; margin-top: .5rem">
            <button pButton
                    [disabled]="!complet()"
                    label="Soumettez pour {{formServ.nomMembreForm}}"
                    icon="pi pi-check"
                    (click)="soumettre()"
            ></button>
            <div *ngIf="xhr.local" style="margin-top: 1rem; opacity: 25%">
                <a style="margin-right: 2rem"
                   (click)="setResults(false, false, false, false)">Tout bon</a> <a (click)="setResults(true, true, true, true)">partiel</a>
            </div>

        </div>


    </div>
</app-page-std>
