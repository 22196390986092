<p-card styleClass="form-status w-11 md:w-9 border-2 border-500 shadow-5 scalein">

    <p-header>
        <div class="grid grid-nogutter">
            <div class="col-2">
                <app-label-component label="form-status" [overlay]="true">
                    <pre>{{dossier | json}}</pre>
                </app-label-component>
            </div>
            <div class="col-8">
                <h2 style="margin: 0">
                    <ng-container *ngIf="dossier; else dossier_visiteur_absent">
                        {{dossier.nom}}
                    </ng-container>
                </h2>
                {{formDesc}}
            </div>
            <div class="col-2 text-xs">

                <p-badge *ngIf="codesEth.length == 0"
                        value="!"
                        severity="danger"
                         [styleClass]="'shadow-5'"

                ></p-badge>
                <p-badge *ngFor="let code of codesEth" [severity]="statusSeverity(code)" [value]="code"
                         styleClass="shadow-5"
                >

                </p-badge>
            </div>
        </div>
    </p-header>

    <ng-container *ngIf="!pageServ.depotInterditSansCodeEth || codesEth.length; else signerCodeEthique">
        <div style="margin: -5px auto .25rem"><span class="highlight-info">{{dernierDepotMsg}}</span>
            <div *ngIf="misAJourDepuisDepot">** formulaire mis à jour depuis **</div>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between">
            <button pButton label="Remplir" icon="pi pi-pencil"
                    (click)="remplirForm()"
                    [disabled]="restrictionSante || depotInterdit"
            ></button>
            <p-toggleButton

                    onIcon="pi pi-check" [onLabel]="'à déposer'"
                    offIcon="pi pi-question" [offLabel]="'à déposer'"
                    [(ngModel)]="choisi"
                    [disabled]="!valide() || restrictionSante || depotInterdit"
                    [styleClass]="choisi ? 'bg-green-500' : 'surface-400'"
                    iconPos="right"
            ></p-toggleButton>
        </div>
        <div *ngIf="session.debug">
            id = {{dossier.id}} Choisis = {{formServ.idsChoisisPourDepot | json}}
        </div>

        <div *ngIf="derniereInstance || restrictionSante || depotInterdit; else aucun_formulaire_soumis" class="rempli" [class.expire]="!valide()">

            <ng-container *ngIf="!restrictionSante && !depotInterdit; else restriction">
                <span *ngIf="!valide()">A expiré</span>
                <span *ngIf="valide()">Expirera</span>
                {{msgExpire}}
            </ng-container>
            <ng-template #restriction>
<!--                <i class="fas fa-exclamation-triangle p-error text-pink-500"></i>-->
                <fa-icon [icon]="faExclamationTriangle" class="p-error text-pink-500"></fa-icon>

                {{depotInterdit && depotInterditRaison ?
                  depotInterditRaison
                  : 'Confirmez état de santé avant de déposer'}}


            </ng-template>
        </div>
        <div *ngIf="depotInterdit" class="text-pink-500 text-xs text-center">Un contact suspecté pour un membre d'une famille peut en affecter tous les membres.</div>

    </ng-container>
    <ng-template #signerCodeEthique>
        <div class="text-center border-pink-500 mt-3 mb-3 p-3 border-1 m-2 shadow-2 border-round">
            <ng-container *ngIf="session.idVisiteur == dossier.idResp; else nonDependant">
                Vous devez d'abord <a class="cursor-pointer" [routerLink]="['/code-ethique', dossier.id]">signer le code
                    d'éthique</a> et la lettre de reconnaissance de risque
                qui s'applique à la situation de {{dossier.nom}}.

            </ng-container>
            <ng-template #nonDependant>
                <ng-container *ngIf="session.idVisiteur == dossier.id; else autreRepondant">
                    Vous devez d'abord <a class="cursor-pointer" [routerLink]="['/code-ethique', dossier.id]">signer le code
                    d'éthique</a> et la lettre de reconnaissance de risque
                    qui s'applique à votre situation.
                </ng-container>
            </ng-template>
            <ng-template #autreRepondant>
                {{dossier.nomResp}} doit signer comme répondant le code d'éthique et la lettre de reconnaissance de risque
                de {{dossier.nom}}. Seule la personne ayant enregistré un mineur peut signer les documents pour lui.
            </ng-template>
        </div>
    </ng-template>


</p-card>
<ng-template #dossier_visiteur_absent><span>Votre dossier n'est pas disponible</span></ng-template>
<ng-template #aucun_formulaire_soumis>
    <div class="rempli aucun-soumis">
        Aucun formulaire soumis dans la dernière semaine
    </div>
</ng-template>
