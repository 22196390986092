<div class="layout-topbar">
    <a class="logo" href="/">
        <img src="assets/layout/images/logo.png" alt="bravesnm-logo"/>
    </a>
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
        <fa-icon [icon]="alignLeft"></fa-icon>
    </a>

    <a *ngIf="session.superhero || session.local" (click)="toggleDebug()" class="cursor-pointer text-4xl m-2"
       style="position:absolute;left: 310px"
    >
        <fa-icon [icon]="fasBug" [class.text-pink-500]="session.debug"  ></fa-icon>
    </a>

    <a id="right-sidebar-button" href="#" (click)="app.onRightPanelButtonClick($event)">
        <fa-icon [icon]="ellipsis"></fa-icon>
    </a>
</div>
