<app-page-std [pathRetour]="['..']" fadein>
    <app-label-component label="page-depot-formulaire"></app-label-component>
    <header>Dépôt de formulaire(s) à un événement</header>
    <div [class.dim-refresh]="refreshing" class="grid col-12">
        <div class="info col-12">
            <p>Maintenant que vous avez rempli votre formulaire ainsi que, le cas échéant, les formulaires de vos
                dépendants,
                déposez-les à une activité auquel vous assistez, au moment de votre arrivée ou peu avant.</p>
            <p>Un formulaire pourrait expirer entre le moment où vous l'avez rempli et celui où vous le déposez
                (un formulaire n'est bon que pour les trois heures qui suivent le moment où il a été rempli). Si cela
                se produit, retournez simplement à la page précédente et remplissez-le à nouveau.</p>
        </div>
        <div class="col-12">
            <div class="title">Les formulaires des personnes suivantes seront déposés:</div>
            <div style="margin-bottom: .25rem" *ngFor="let membre of liste">{{membre.nom}}</div>
        </div>
        <div *ngIf="xhr.local" class="local col-12">
            idMembreForm = {{formServ.idMembreForm}}; nomMembreForm = {{formServ.nomMembreForm}}
        </div>

        <div *ngIf="events.length; else aucun_ev" class="col-12 flex flex-column align-items-center">
            <div class="title">Choisissez le lieu de l'activité</div>
            <div class="info">lieux avec activité maintenant +/- 1 heure</div>


            <p-dropdown [(ngModel)]="lieuChoisi" (onChange)="evChoisi = null;setChoixEv()"
                        [options]="lieuxEventsOptions"
            ></p-dropdown>

            <div class="title">Désignez l'activité où vous vous présentez</div>
            <div *ngIf="lieuChoisi; else choisir_lieux" class="grid col-12 flex align-items-center flex-column">

                <div *ngFor="let ev of filteredEvents" class="col-12 grid ligne hover_ptr"
                     [class.futur]="futur(ev.heure) && !tropTardDisabled(ev)"
                     (click)="evChoisi = !tropTardDisabled(ev) ?  ev.id : evChoisi">
                    <div class="col-11 grid">


                        <div class="col-12 md:col-4">
                            {{format(ev.heure)}}
                            <span style="font-weight: bold"> ({{humanize(ev.heure)}})</span>
                            <app-label-component [overlay]="true">
                                <button pButton label="log ev" (click)="consoleLog(ev)"></button>
                            </app-label-component>
                        </div>
                        <div class="col-12 md:col-8 resp">
                            {{ev.type}} <span>{{ev.descr || ev.resp }}</span> <span *ngIf="session.debug">[id {{ev.id}}{{ev.test ? '*' : ''}}]</span>
                            <div *ngIf="tropTardDisabled(ev)" style="color: red; text-align: right">** trop tôt pour choisir cette activité!</div>
                        </div>


                    </div>
                    <div class="col-1 flex flex-column align-items-center">
                        <p-radioButton
                                [disabled]="tropTardDisabled(ev)"
                                name="choix_lieu"
                                [(ngModel)]="evChoisi"
                                [value]="ev.id"
                        ></p-radioButton>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="grid col-12">
        <div class="col-12">
            Si <b>et seulement si</b> vous ne trouvez pas l'activité à laquelle vous allez participer ci-dessus,
            vous pouvez déposer votre formulaire sur le site que vous visitez. Dans ce cas, désignez le site
            que vous allez visiter:
            <div *ngIf="evChoisi ==  '-1'" style="color: red; margin: 1rem; padding: .5rem; border-radius: 1rem; box-shadow: 0 0 10px #ccc">
                Êtez-vous bien sûr(e) de ne pas voir votre activité plus haut? La liste pour chaque
                aréna comprend les activités commençant dans les 2 prochaines heures. Laissez à votre
                activité le temps d'apparaître dans la liste. Si vous remplissez votre formulaire
                trop tôt, il sera expiré au moment de vous présenter à l'aréna et il ne sera pas accepté.
            </div>

        </div>
        <div class="col-12 grid  ligne" style="text-align: center">
            <div class="col-2"></div>
            <div class="col-8">
                <p-dropdown [options]="lieuxTousOptions" [(ngModel)]="siteNoEvent"></p-dropdown>
            </div>
            <div class="col-2 flex align-items-center">
                <p-radioButton
                        name="choix_lieu"
                        [(ngModel)]="evChoisi"
                        [value]="-1"
                ></p-radioButton>
            </div>
        </div>
        <div class="col-12">
            <button [loading]="refreshing" [disabled]="refreshing" pButton icon="pi pi-refresh" label="Rafraîchir" (click)="refresh()"></button>

        </div>

    </div>    <div style="margin: 1.5rem auto">
        <button pButton label="Déposer le formulaire"
                (click)="deposer()"
                [disabled]="!evChoisi || evChoisi == -1 && !siteNoEvent"></button>
    </div>

</app-page-std>

<ng-template #choisir_lieux>
    <div>
        Vous n'avez pas encore choisi le lieux de l'activité
    </div>
</ng-template>

<ng-template #aucun_ev>
    <div class="info-aucune-activite">
        Hélas aucune activité n'est trouvée à l'heure présente plus ou moins une heure!

    </div>
</ng-template>

