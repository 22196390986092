/* tslint:disable:no-bitwise */
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as R from 'ramda';
import {concat, from, range, zip} from 'rxjs';
import {bufferCount, map, skip, take, toArray} from 'rxjs/operators';
import {Md5} from 'ts-md5';
import {StoreInterface} from '../app/app.module';
import {SelectorsService} from './selectors.service';
import {confirmerEnvoiCode, logOff, login} from '../store/actions/session.actions';
import {SessionService} from './session.service';
import {GrowlService} from './growl.service';
import {XhrService} from './xhr.service';
import {PageService} from './page.service';
import {Router} from '@angular/router';
import {initDossiersLies, resetDossiersLies} from '../store/actions/dossiersLies.actions';
import {formulairesInit} from '../store/actions/formulaires.actions';

declare const grecaptcha: any;
export interface ValidationRes { [key: string]: any; }
@Injectable({
    providedIn: 'root'
})
export class LoginService {


    failedRoute;

    currentState: StoreInterface;

    constructor(
        public store: Store<StoreInterface>,
        public xhrServ: XhrService,
        public growl: GrowlService,
        public sel: SelectorsService,
        public sessionServ: SessionService,
        public pageServ: PageService,
        public router: Router
    ) {
        this.store.subscribe((currentState: StoreInterface) => {
            this.currentState = currentState;
        });
        
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => console.log(position));
        // } else {
        //     console.log("Geolocation is not supported by this browser.");
        // }
    }

    get loggedIn() {
        return this.sessionServ.isLoggedIn();
    }

    async login(courriel, mdp) {
        courriel = R.trim(courriel);
        // const s = this.scramble(mdp);
        // const sha1 = require('sha1');
        // const salt = this.currentState.session.sel;


        const data = await this.xhrServ.post('login_visiteur', 'login', {
            courriel_ou_pseudo: courriel,
            mdp: this.scramble(mdp),
            captcha_token: await this.getCaptchaToken('login')
        }, {noThrow: true});

        if (data.result) {
            // this.store.dispatch(login(data.login))
            // await this.pageServ.getInitData();
            this.pageServ.processInitData(data.init_data);
            this.growl.info('Vous êtes reconnu');
            return true;
        } else {
            this.growl.xhr_error(data);
            return false;
        }

    }
    

    async fakeLogin(idAssoc) {
        const data = await this.xhrServ.post('login_visiteur', 'getAssocLoginInfo', {idAssoc}, {noThrow: true});
    }

    async changerMdp(courant, nouveau) {
        const scrCourant = this.scramble(courant);
        const scrNouv = this.scramble(nouveau);
        const data = await this.xhrServ.post('login_visiteur', 'changer_mdp',
          {courant: scrCourant, nouveau: scrNouv,
          captcha_token: await this.getCaptchaToken('change pw')
          }, {noThrow: true}
          )
        if (data.result) {
            this.growl.info('Mot de passe changé');
            return true;
        }
        this.growl.xhr_error(data);
        return false;
    }

    scramble(pw: string): string {

        const salt = this.currentState.session.sel;
        let addition = this.currentState.session.sessionId;
        if (!salt || !addition) {
            throw new Error('Donnée manquante pour traitement de mot de passe');
        }
        // console.log('salt', salt);
        const sha1 = require('sha1');
        pw = sha1(salt + Md5.hashStr(pw));
        // console.log('session id = ', addition);
        addition += Date.today().toString('yyyyMMdd');
        pw += addition + String.fromCharCode(addition.length);

        const keyValues = [];
        range(0, 16)
            .pipe(map(val => Math.floor(Math.random() * 255)))
            .subscribe(v => keyValues.push(v));

        const res = [];
        const repeatedKeys = this.obtainKeys(keyValues, pw.length);
        // console.log('====================================================');
        // console.log('key values', keyValues);
        // console.log('repeated keys', repeated_keys);
        // console.log('pw', pw);
        concat(
            from(keyValues)
            ,
            zip(
                from(repeatedKeys)
                    // tslint:disable-next-line:no-bitwise
                    .pipe(map((v, ind) => ind % 2 === 0 ? (v << 1) : v >> 1))
                ,

                from(pw)
                    .pipe(map((v) => v.charCodeAt(0)))
            ).pipe(
                // tslint:disable-next-line:no-bitwise
                map(([a, b]) => a ^ b)
            )
        )
            .subscribe(v => res.push(('00' + v).substr(-3)));
        // console.log('res', res);
        return res.join('');
    }

    obtainKeys(vals: number[], nb: number): number[] {
        let res = [];
        while (nb > 0) {
            res = res.concat(vals.slice(0, nb));
            nb -= vals.length;
        }
        return res;
    }

    unscramble(pwString: string): string {

        let pw: number[] = [];
        from(pwString)
            .pipe(
                bufferCount(3),
                map(val => parseInt(val.join(''), 10)),
                toArray()
            )
            .subscribe(v => pw = v)
        ;

        let res = '';
        const keys = [];
        from(pw)
            .pipe(
                take(16),
                // tslint:disable-next-line:no-bitwise
                map((v, i) => (i % 2 === 0) ? (v << 1) : v >> 1)
            )
            .subscribe(v => keys.push(v));

        const repeatedKeys = this.obtainKeys(keys, pw.length - 16);

        zip(
            from(repeatedKeys)
            ,
            from(pw).pipe(skip(16))
            ,
            (a, b) => a ^ b
        ).pipe(
            toArray()
        )
            .subscribe(list => {
                const nbAdded = list.pop();
                res = list
                    .slice(0, list.length - nbAdded)
                    .map(v => String.fromCharCode(v))
                    .join('');
            })
        ;
        return res;
    }

    validate_mdp(control): ValidationRes {
        const val = R.trim(control.value);
        if (val !== control.value) {
            control.setValue(val);
            return;
        }

        const result: ValidationRes = {};
        if (!/[A-Z]/.test(val)) {
            result.doit_inclure_maj = true;
        }
        if (!/[a-z]/.test(val)) {
            result.doit_inclure_min = true;
        }
        if (!/.*[0-9].*/.test(val)) {
            result.doit_inclure_chiffre = true;
        }
        if (!/[^0-9a-z]/i.test(val)) {
            result.doit_inclure_autre = true;
        }
        if (val.length < 6) {
            result.trop_court = true;
        }
        return result;
    }

    async demanderCodeValidation(courriel) {

        const data = await this.xhrServ.post('login_visiteur', 'envoyer_courriel', {
            courriel
        }, {noThrow: true});
        if (!data.result) {
            this.growl.xhr_error(data);
            return false;
        } else {
            this.growl.info('Code envoyé', 'Vérifiez vos courriels à l\'adresse fournie.');
            this.store.dispatch(confirmerEnvoiCode({courrielCodeEnvoye: data.courriel, heureCodeEnvoye: data.heure}));
            return true;
        }
    }


    async getCaptchaToken(action = 'homepage') {
        if (grecaptcha && (window.location.hostname || '').indexOf('localhost') === -1) {

            return await grecaptcha.execute('6LfbKMIZAAAAAHdR1mbsHmeL-b16I7OfBIbORdf7', {action});
            // grecaptcha.ready(async () => {
            //
            //   const promise = grecaptcha.execute('6Lfvn70UAAAAAL-y3zzYSRCcz5PekmEX6GwuOl7K', {action: 'homepage'});
            //   console.log('promise', promise);
            //   if (promise) {
            //     promise
            //       .then((token = null) => {
            //         console.log('captcha token', token);
            //       })
            //
            //     ;
            //   }

            // });
        } else {
            return 'local';
        }
    }

    async logout() {
        const data = await this.xhrServ.get('login_visiteur', 'logout', {}, {noThrow: true});
        if (data.result) {
            this.router.navigate(['/login']);
            this.growl.info('Session fermée', 'Vous n\'êtes plus reconnu');
            setTimeout(() => {
                this.store.dispatch(logOff());
                this.store.dispatch(resetDossiersLies());
                this.store.dispatch(formulairesInit());
            });
            
            
            
            
            return true;
        }
        this.growl.xhr_error(data);
        return false;
    }

    async getStatutCourriel(courriel) {
        const data = await this.xhrServ.get('login_visiteur', 'verifier_courriel', {courriel}, {noThrow: true});
        if (data.result) {
            return R.split(',', data.statut);
        } else {
            this.growl.xhr_error(data);
            return [];
        }
    }

    async annulerCode(courriel) {
        const data = await this.xhrServ.post('login_visiteur', 'annuler_code_validation', {courriel}, {noThrow: true});
        if (!data.result) {
            this.growl.xhr_error(data);
        }
        return !!data.result;

    }


    async remplacerMdpPerdu(courriel, mdp, code) {

        mdp = this.scramble(mdp);

        const data = await this.xhrServ.post('login_visiteur', 'remplacer_mdp_perdu', {courriel, mdp, code}, {noThrow: true});
        if (data.result) {
            this.growl.info('Succès!');
            return true;
        }
        this.growl.xhr_error(data);
        return false;
    }

    async remplacermdpPerduAdmin(courriel, mdp, code, pseudo) {
        mdp = this.scramble(mdp);

        const data = await this.xhrServ.post('login_visiteur', 'remplacer_mdp_perdu_admin',
            {courriel, mdp, code, pseudo}, {noThrow: true});
        if (data.result) {
            this.growl.info('Succès!');
            return true;
        }
        this.growl.xhr_error(data);
        return false;
    }
}
