import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import * as R from 'ramda';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { GrowlService } from './growl.service';
import {LoginService} from './login.service';


declare let $: JQueryStatic;

export interface XhrOptions {
  overlay?: boolean | JQueryStatic | JQuery;
  observableResult?: boolean;
  withCredentials?: boolean;
  noThrow?: boolean;
  throwVal?: any;
  onStart?: () => {};
  onDone?: () => {};
  context?: any;
  url?: string;
}

export const stdXhrOpts = {noThrow: true, overlay: true};

export class ContextXhrServ {
  constructor(
    public xhr: XhrService,
    public growl: GrowlService,
    public context: string,
    public loginServ: LoginService
  ) {
  }

  async get(op: string, data: {}, opts?: XhrOptions) {
    opts = opts || {};
    opts = R.mergeLeft(opts, {overlay: true, noThrow: false});
    try {
      if (opts && opts.onStart) {
        opts.onStart.call(opts.context || this.xhr);
      }
      const result = await this.xhr.get(this.context, op, data, opts);
      // console.log('from xhr.get', result);
      if (opts && opts.onDone) {
        opts.onDone.call(opts.context || this.xhr);
      }
      if (!result.result) {
        this.growl.xhr_error(result);
        if (result.logout) {
          if (this.loginServ.loggedIn) {
            this.loginServ.logout();
          }


        }
      }
      return result;
    } catch (err) {

      if (opts && opts.onDone) {
        opts.onDone.call(opts.context || this.xhr);
      }
      this.growl.xhr_error(err);
      if (!opts.noThrow) {
        if (opts.throwVal === undefined) {
          throw err;
        }
        throw opts.throwVal;
      }
      return opts.throwVal === undefined ? err : opts.throwVal;
    }

  }

  async post(op: string, data: {}, opts?: XhrOptions) {
    opts = opts || {};
    opts = R.mergeLeft(opts, {overlay: true, noThrow: false});
    try {
      if (opts && opts.onStart) {
        opts.onStart.call(opts.context || this.xhr);
      }

      // console.log('posting');
      const result = await this.xhr.post(this.context, op, data, opts);
      // console.log('from xhr.post', result);
      if (opts && opts.onDone) {
        opts.onDone.call(opts.context || this.xhr);
      }
      if (!result.result) {
        this.growl.xhr_error(result);
      }
      return result;
    } catch (err) {
      if (opts && opts.onDone) {
        opts.onDone.call(opts.context || this.xhr);
      }

      this.growl.xhr_error(err);
      if (!opts.noThrow) {
        if (opts.throwVal === undefined) {
          throw err;
        }
        throw opts.throwVal;
      }
      return opts.throwVal === undefined ? err : opts.throwVal;
    }

  }
}

@Injectable()
export class XhrService {
  public local = (window.location.hostname.indexOf('localhost') > -1);

  public url;
  public urlPHP;
  public localhost = '//' + window.location.hostname + (window.location.port ? ':' : '') + window.location.port;
  public ngServer = (window.location.port + '' === '4200');
  public xhrBusy: boolean;
  public xhrFin = new EventEmitter();
  public xhrFinDelay = new EventEmitter();
  private timeoutFinXhr;

  constructor(
    private http: HttpClient,
    private growl: GrowlService,
    public store: Actions
  ) {
    // console.log('localhost était ', this.localhost);
    if (this.local && this.ngServer) {
      this.localhost = '//covid.localhost';
    }
    // console.log('localhost est ' , this.localhost);

    // console.log('localhost', this.localhost, 'ngserver: ', (this.ngServer  ? 'OUI' : 'NON'));
    this.url = this.localhost + `/PHP/process_request2.php`;
    // console.log('hostname = ' + this.localhost);
    this.urlPHP = (this.local) ? this.localhost + `/PHP` : './PHP';
    // this.urlPHP = this.local ? `http://${location.host}/PHP` : './PHP';
    if (!this.local) {
      this.url = 'PHP/process_request2.php';
    }

  }


  post(context: string, op: string, data: {}, options?: XhrOptions) {
  
    const headers = new HttpHeaders(
      {
        'Content-Type': 'text/plain; charset=UTF-8'
      }
    );
    const xhrOptions = {headers, withCredentials: true, crossDomain: this.local }; // TODO !!!!!

    const body = JSON.stringify({context, op, data});

    // console.log('myxhr...1');
    this.xhrBusy = true;
    const observable = this.http.post(
      options && options.url || this.url,
      body,
      xhrOptions);
    return this.process(observable, options);

  }

  get(context: string, op: string, data = {}, options?: XhrOptions) {
    // console.log('get', context, op, data, options);
    let params = new HttpParams(
      //   {
      //   context,
      //   op,
      //   data: JSON.stringify(data)
      // } as HttpParamsOptions
    );
    params = params.set('context', context);
    params = params.set('op', op);
    params = params.set('data', JSON.stringify(data));
    const headers = new HttpHeaders(
      {
        'Content-Type': 'text/plain; charset=UTF-8'
      }
    );
    // console.log(params);
    // console.log(params.toString());

    const xhrOptions = {headers, observe: 'body', params, withCredentials: true, responseType: 'json'} as any;
    // const url = this.url + '?' + params.toString();
    // console.log('url', url);
    this.xhrBusy = true;
    return this.process(this.http.get(options && options.url || this.url, xhrOptions), options);
  }

  process(httpObservableResult, options: XhrOptions = {}) {
    let overlayTarget: any;
    if (options.overlay) {
      if (R.is(Object, options.overlay)) {
        overlayTarget = options.overlay;
      } else {
        overlayTarget = $('body');
      }
      overlayTarget.plainOverlay('show');
    }
    const toRet = httpObservableResult.pipe(
      map((resp: Response) => {
        if (overlayTarget) {
          overlayTarget.plainOverlay('hide');
        }
        // console.log('.......................', resp);
        setTimeout(() => {
          this.xhrBusy = false;
          this.xhrFin.emit();
        });
        if (this.timeoutFinXhr) {
          clearTimeout(this.timeoutFinXhr);
        }
        this.timeoutFinXhr = setTimeout(() => this.xhrFinDelay.emit(this.xhrBusy), 100);
        return this.extractData(resp);
      }),
      catchError((data) => {
          if (overlayTarget) {
            overlayTarget.plainOverlay('hide');
          }
          data.result = 0;
          throw(data);
        }
      )
  );

    if (options.observableResult) {
      return toRet;
    }
    if (!options.noThrow) {
      return toRet.toPromise();
    }

    return toRet.toPromise()
      .then(
        data => {
          return data;
        }
        ,
        data => {
          // console.log('returning after throwing', data, 'options', options);
          // console.log('........returning ' , options.throwVal !== undefined ? options.throwVal : data);
          return (options.throwVal !== undefined ? options.throwVal : data);
        }
      );

  }

  extractData(res: any) {
    if (res === null) {
      throw {msg: 'Réponse nulle du serveur'};
    }
    if (res.status < 200 || res.status >= 300) {
      throw {msg: 'erreur_comm_serveur', ref: res.status, result: 0};
    }
    const body = res;
    if (body.result === 0) {
      // console.log('throwing', body);
      throw body;
    }
    return body;
  }

  async complete(msg = 'xhr') {
    return await this.store.pipe(
      ofType(msg),
      take(1)
    ).toPromise();

  }

  mergeMapFn(action: ActionCreator | ActionCreator[], returnType = 'xhr') {
    return mergeMap((data) => {
        return new Observable(s => {
          let actions;
          if (action instanceof Array) {
            actions = action;
          } else {
            actions = [action];
          }
          s.next({type: returnType, payload: data});
          for (const act of actions) {
            s.next((act as ActionCreator)(data));
          }

          s.complete();
        });
      }
    ) as any;
  }
  catchError(msg = 'xhr') {
    return catchError((data: any) => {
      this.growl.xhr_error(data);
      return of({type: msg, payload: data});
    });
  }


}
