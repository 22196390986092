import {EventEmitter, Injectable} from '@angular/core';
import {XhrService} from './xhr.service';
import {initRefData} from '../store/actions/refData.actions';
import {Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';

import {
  login,
  logOff,
  setCourrielsModeTest,
  setDepotsInterdits,
  setRefusLogins,
  setSel,
  setSessionId
} from '../store/actions/session.actions';
import {initDossiersLies, resetDossiersLies} from '../store/actions/dossiersLies.actions';
import {formulairesSet} from '../store/actions/formulaires.actions';
import {GrowlService} from './growl.service';
import {ReplaySubject, Subscription} from 'rxjs';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {SessionService} from './session.service';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  public initialized = false;
  public initEmitter = new EventEmitter();
  public idForm = '1';
  public depotInterditSansCodeEth = false;
  public subs: Subscription[] = [];
  public pageInitReplaySubject = new ReplaySubject<boolean>(1);
  public routerNavigation = false;
  public versionServeur;
  constructor(
    public xhr: XhrService,
    public store: Store<StoreInterface>,
    public growl: GrowlService,
    public router: Router,
    public sessionServ: SessionService
  ) {

    this.getInitData();
    this.pageInitialized();
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.routerNavigation = true;
      } else if (ev instanceof  NavigationCancel || ev instanceof NavigationError || ev instanceof NavigationEnd) {
        this.routerNavigation = false;
      }
    })
  }
  
  async getInitData() {
    
    const data = await this.xhr.post('init_data', 'get_init_data', {}, {noThrow: true});
    if (data.result) {
      this.processInitData(data);
    } else {
      this.growl.xhr_error(data);
    }
    this.initialized = true;
    this.initEmitter.emit(true);
  
    this.pageInitReplaySubject.next(true);
  }
  
  processInitData(data) {
    this.depotInterditSansCodeEth = !!data.depot_interdit_sans_code_eth;
    this.store.dispatch(initRefData({
      typesMembres: data.types_membres,
      lieux: data.lieux,
      typesEvents: data.types_events,
      deltaMinJeune: data.deltaMinJeune,
      ageMaxJeune: data.ageMaxJeune,
      ageMinFormSante: data.ageMinFormSante
    }));
    if (data.version) {
      this.versionServeur = data.version;
    }
    if (data.courrielsModeTest !== undefined) {
      this.store.dispatch(setCourrielsModeTest(data));
    }
    this.store.dispatch(setSel(data));
    this.store.dispatch(setSessionId(data));
    if (!data.login_data) {
      this.store.dispatch(logOff());
    } else {
      this.store.dispatch(login(data.login_data));
    }
    if (R.allPass([R.has('interdictionDepots'), R.has('dateReprise'), R.has('messageInterdictionDepots')], data)) {
      this.store.dispatch(setDepotsInterdits(data));
    }
    if (data.refuserLogins !== undefined) {
      this.store.dispatch(setRefusLogins(data));
    }
    if (data.dossiers_lies) {
      const doss = data.dossiers_lies;
      if (!doss.idParent) {
        this.store.dispatch(resetDossiersLies());
      } else {
        this.store.dispatch(initDossiersLies(doss));
      }
    } else {
      this.store.dispatch(resetDossiersLies());
    }
    if (data.formulaires) {
      this.store.dispatch(formulairesSet({forms: data.formulaires}));
    }
    this.sessionServ.updateStatus();
  }
  
  pageInitialized() {
    return this.initialized || this.initEmitter;
    
  }
  
  async getCodeIdentification() {
    const data = await this.xhr.get('gestion', 'getCodeIdent', {}, {noThrow: true});
    if (data.result) {
      return data.code;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
}
