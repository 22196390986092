import {Component, Injectable} from '@angular/core';
import {AppComponent} from './app.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SessionService} from '../services/session.service';
import {LoginService} from '../services/login.service';
import {XhrService} from '../services/xhr.service';
import {GrowlService} from '../services/growl.service';
import {MessageService} from 'primeng/api';
import {Store} from '@ngrx/store';
import {StoreInterface} from './app.module';
import {debugOff, debugOn} from '../store/actions/session.actions';

import {faCogs, faExclamationTriangle, faFile, faGlobe, faHandPaper, faHockeyPuck, faPrint, faStamp, faUpload, faUser} from '@fortawesome/free-solid-svg-icons';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {faUserSlash} from '@fortawesome/free-solid-svg-icons';
import {faUserCog} from '@fortawesome/free-solid-svg-icons';
import {faUserFriends} from '@fortawesome/free-solid-svg-icons';
import {faUserEdit} from '@fortawesome/free-solid-svg-icons';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {faBug} from '@fortawesome/free-solid-svg-icons';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {version} from '../environments/version';
import {PageService} from '../services/page.service';


@Injectable()
@Component({
  selector: 'app-inline-profile',
  templateUrl: 'app.profile.component.html',
  animations: [
    trigger('menu', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*'
      })),
      state('hidden', style({
        height: '0px'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  styles: [
    `a fa-icon{
        margin-right: .5rem;
    }
    `,
    `ul li {
          background-color: #d7e0e8;

      }`,
    `
          ul li :hover {
              background-color: #98b3c9;
          }`
  ]
})
export class AppProfileComponent {
  
  faUser = faUser;
  faUserEdit = faUserEdit;
  faUserPlus = faUserPlus;
  faUserSlash = faUserSlash;
  faUserCog = faUserCog;
  faUserFriends = faUserFriends;
  faCalendarAlt = faCalendarAlt;
  faTrashAlt = faTrashAlt;
  faBug = faBug;
  faKey = faKey;
  faAngleDown = faAngleDown;
  faCogs = faCogs;
  faHockeyPuck = faHockeyPuck;
  faGlobe = faGlobe;
  faFile = faFile;
  faHandPaper = faHandPaper;
  faPrint = faPrint;
  faExclamationTriangle = faExclamationTriangle;
  faStamp = faStamp;
  faUpload = faUpload;
  
  version = version;
  showDialVersion: boolean;
  nouvelleVersion: string;
  constructor(
    public app: AppComponent,
    public sessionServ: SessionService,
    public loginServ: LoginService,
    public pageServ: PageService,
    public xhr: XhrService,
    public growl: GrowlService,
    public msg: MessageService,
    public store: Store<StoreInterface>
  ) {
    
  }
  
  onProfileClick(event) {
    this.app.usermenuClick = true;
    this.app.usermenuActive = !this.app.usermenuActive;
    event.preventDefault();
  }
  
  onProfileItemClick(event, item) {
    this.app.usermenuClick = true;
    if (this.app.activeProfileItem === item) {
      this.app.activeProfileItem = null;
    } else {
      this.app.activeProfileItem = item;
    }
    
    event.preventDefault();
  }
  
  onProfileSubItemClick(event) {
    event.preventDefault();
  }
  
  fermerSession() {
    this.loginServ.logout();
  }
  
  async creer_evs() {
     const data = await this.xhr.post('gestion_events', 'create_random_events', {}, {noThrow: true});
    
     if (data.result) {
       this.growl.info('OK');
     } else {
       this.growl.xhr_error(data);
     }
  }
  
  async effacer_evs(tous) {
    const data = await this.xhr.post('gestion_events', 'delete_ramdom_events', {all: tous ? 1 : 0}, {noThrow: true});
    if (data.result) {
      this.msg.add({summary: `${data.deleted} messages effacés`, severity: 'info'});
    } else {
      this.growl.xhr_error(data);
    }
  }
  
  toggleDebug() {
    if (this.sessionServ.debug) {
      this.store.dispatch(debugOff());
    } else {
      this.store.dispatch(debugOn());
    }
  }
  
  setNoVersion() {
    this.showDialVersion = true;
  }
  async sauvegarderVersion() {
    const data = await this.xhr.post('gestion', 'set_no_version', {version: this.nouvelleVersion}, {noThrow: true});
    if (!data.result) {
      this.growl.xhr_error(data);
      return;
    }
    this.pageServ.versionServeur = this.nouvelleVersion;
    this.growl.info('Numéro de version réglé sur le serveur à ' + this.nouvelleVersion);
    this.showDialVersion = false;
  }
}
