import {Injectable} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {Depot, gestionInitialState, GestionState, User} from '../store/reducers/gestion.reducer';
import {StoreInterface} from '../app/app.module';
import * as R from 'ramda';
import * as moment from 'moment';
import {XhrService} from './xhr.service';
import {
  gestionConfirmerPresence,
  gestionDeplacerDepot,
  gestionEffaceEvenement,
  gestionSaveEvent,
  gestionSet, gestionUpdateEvent
} from '../store/actions/gestion.actions';
import {GrowlService} from './growl.service';
import {Lieu, lieuSortFn} from '../store/reducers/refData.reducer';
import {FormEvent} from '../store/reducers/formulaires.reducer';
import {refDataEffaceLieu, refDataSaveLieu} from '../store/actions/refData.actions';
import {CourrielAvis} from '../app/page-courriels-avis/page-courriels-avis.component';
import {stripAccents} from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class GestionService {
  public ref: GestionState = gestionInitialState;
  public initialized = false;
  public debutEv = '?';
  public finEv = '?';
  public dureeEv = 0;
  public userOptions: {label: string, value: string}[] = [];
  public admUserOptions: {label: string, value: string, disabled?: boolean}[] = [];
  public lieuxOptions: {label: string, value: string}[] = [];
  public sortedIdsLieux = []; // ids lieux en ordre alpha de nom de lieu
  public sortedIdsRef = {};
  public refLieux = {};
  
  public lieuxEv: {[idLieu: string]: FormEvent[]};
  public userRef: {[id: string]: User};
  
  constructor(
    public store: Store<StoreInterface>,
    public xhr: XhrService,
    public growl: GrowlService
  ) {
    
    this.store.select(createSelector(R.prop('gestion'), R.identity)).subscribe((val: GestionState) => {
      this.ref = val as GestionState;
      
      this.lieuxEv = R.groupBy(R.prop('lieu'))(this.ref.evenements);
      if (this.ref.debutEv) {
        this.debutEv = moment(this.ref.debutEv).format('dddd D MMM');
        this.finEv = moment(this.ref.finEv).format('dddd D MMM');
        this.dureeEv = moment(this.ref.finEv).diff(this.ref.debutEv, 'days') + 1;
      }
      const naissance16Ans = new Date().getFullYear() - 16;
      this.userOptions = R.pipe(
        R.prop('users'),
        R.values,
        R.filter(R.pipe(R.prop('anneeNaissance'), R.gt(naissance16Ans))),
        R.map(user => ({label: `${user.nom}, ${user.prenom} [${user.anneeNaissance}]`, value: user.id})),
        R.prepend({value: null, label: 'Faites un choix'})
      )(val);
      this.admUserOptions = R.pipe(
        R.prop('users'),
        R.values,
        R.filter(R.anyPass([
         // R.prop('permVerif'),
          R.prop('permCreerEv'),
          R.prop('permAdmin'),
          R.prop('permChefAdmin')
        ])),
        R.sortBy(user => user.id === '0' ? '' : stripAccents(user.nom)),
        R.map(user =>
          ({label: user.id === '0' ? '[Administration]' : `${user.nom}, ${user.prenom} [${user.anneeNaissance}]`, value: user.id})
        )
        // ,
        // R.prepend({value: null, label: 'Faites un choix'})
      )(val);
      const adminExiste = R.find(R.propEq('value', '0'), this.admUserOptions);
      if (!adminExiste) {
        this.admUserOptions = R.prepend({label: '[Administration]', value: '0'}, this.admUserOptions);
      }
      this.userRef = val.users;
      
    });
    
    this.store.select(createSelector(R.prop('refData'), R.prop('lieux'))).subscribe(lieux => {
      this.refLieux = R.zipObj(R.pluck('id', lieux), R.values(lieux));
      this.lieuxOptions =
        R.pipe(
          lieuSortFn,
          R.map(val => ({value: val.id, label: val.nom})),
          R.prepend({label: 'Faites un choix', value: null})
        )(lieux);
      this.sortedIdsLieux = R.pluck('value', this.lieuxOptions).slice(1);
      this.sortedIdsRef = R.zipObj(this.sortedIdsLieux, R.range(0, this.sortedIdsLieux.length));
        
      
    });
    
    
  }
  
  adminUserAvailable(avail: boolean) {
    const opt = R.find(R.propEq('value', '0'), this.admUserOptions);
    if (opt) {
      opt.disabled = !avail;
    }
    
  }
  
  async initialize(force = false, debut = null, fin = null) {
    // console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,,,,, initalisation gestion');
    if (!force && this.initialized) {
      // console.log('deja init');
      return true;
    }
    // console.log('wait...............');
    const data = await this.xhr.post('gestion', 'get_init_data', {
      debut, fin
    }, {noThrow: true});
    
    // console.log('............fini');
    if (data.result) {
      this.store.dispatch(gestionSet(data));
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
    
  }
  
  async saveEv(dataEv: FormEvent) {
    const data = await this.xhr.post('gestion_events', 'save_event',
      dataEv, {noThrow: true}
      );
    if (data.result) {
      if (data.id) {
        dataEv.id = data.id;
      }
      this.store.dispatch(gestionSaveEvent({data: dataEv}));
      this.growl.info('OK');
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async saveLieu(dataLieu: Lieu) {
    const data = await this.xhr.post('gestion_lieux', 'save_lieu', dataLieu, {noThrow: true});
    if (data.result) {
      if (data.id) {
        dataLieu.id = data.id;
      }
      
      this.store.dispatch(refDataSaveLieu({data: dataLieu}));
      this.growl.info('OK');
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async effacerLieu(id) {
    const data = await this.xhr.post('gestion_lieux', 'efface_lieu', {id}, {noThrow: true});
    if (data.result) {
      this.growl.info('OK');
      this.store.dispatch(refDataEffaceLieu({id}));
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async effacerEv(id) {
    const data = await this.xhr.post('gestion_events', 'effacer_evenement', {id}, {noThrow: true});
    if (data.result) {
      this.growl.info('OK');
      this.store.dispatch(gestionEffaceEvenement({id}));
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  async toggleVisibilityEv(id) {
    const data = await this.xhr.post('gestion_events', 'changer_visibilite', {id}, {noThrow: true});
    if (!data.result) {
      this.growl.xhr_error(data);
      return false;
    }
    this.store.dispatch(gestionUpdateEvent({data: R.pick(['id', 'invisible'], data)}));
    return true;
  }
  nomLieu(id) {
    return R.pathOr('?', [id, 'nom'], this.refLieux);
  }
  nomUser(id, nomPremier = false) {
    const user = R.prop(id, this.userRef);
    if (!user) {
      return '?';
    }
    if (nomPremier) {
      return user.nom + ', ' + user.prenom;
    }
    return user.prenom + ' ' + user.nom;
    
  }
  
  async confirmerPresence(depot: Depot, conf: boolean) {
    const data = await this.xhr.post('gestion', 'confirmer_presence', {
      id_depot: depot.id,
      conf
    }, {noThrow: true});
    if (data.result) {
      this.store.dispatch(gestionConfirmerPresence(data));
    } else {
      this.growl.xhr_error(data);
    }
  }
  
  async deplacerDepot(depot: Depot, idEv: string) {
    const data = await this.xhr.post('gestion', 'deplacer_depot', {
      id_depot: depot.id,
      id_ev: idEv
    }, {noThrow: true});
    if (data.result) {
      this.store.dispatch(gestionDeplacerDepot(data));
      return true;
    } else {
      this.growl.xhr_error(data);
    }
  }
  
  async getDossierMembre(idMembre) {
    const data = await this.xhr.post('gestion_membres', 'get_info_membre', {idMembre}, {noThrow: true});
    
    if (data.result) {
      return data.info;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async getListeCourrielsAvis() {
    const data = await this.xhr.post('gestion', 'liste_adr_courriels_avis', {}, {noThrow: true});
    if (data.result) {
      return data.liste as CourrielAvis[];
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async soumettreCourrielAvis({id, courriel, instant, quotidien, pause}) {
    const data = await this.xhr.post('gestion', 'soumettre_courriel_avis', {
      id,
      courriel,
      instant,
      quotidien,
      pause
    }, {noThrow: true});
    if (data.result) {
      return data.id || true;
    }
    this.growl.xhr_error(data);
    return false;
  }
  async togglePauseCourrielAvis(id) {
    const data = await this.xhr.post('gestion', 'toggle_pause_courriel_avis', {id}, {noThrow: true});
    if (data.result) {
      return data;
    }
    this.growl.xhr_error(data);
    return false;
  }
  
  async retirerCourrielAvis(id) {
    const data = await this.xhr.post('gestion', 'retirer_courriel_avis', {id}, {noThrow:  true});
    if (data.result) {
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  }
  
  async envoyerResume(idRecord) {
    const data = await this.xhr.post('gestion_forms', 'envoyer_rapport_veille', {id: idRecord}, {noThrow: true});
    if (data.result) {
      this.growl.info('OK');
      return true;
    }
    this.growl.error('Échec');
    return false;
  }
  
  async getRef(ref) {
    const data = await this.xhr.post('gestion_events', 'trouver_ref', {ref}, {noThrow: true});
    if (!data.result) {
      this.growl.xhr_error(data);
      return false;
    }
    return data.record;
  }
  async sauvegarderRef(vals) {
    const data = await this.xhr.post('gestion_events', 'sauvegarder_ref',
      vals, {noThrow: true});
    if (!data.result) {
      this.growl.xhr_error(data);
      return false;
    }
    return data;
  }
}
