import {Component, Injectable} from '@angular/core';
import {AppComponent} from './app.component';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {SessionService} from '../services/session.service';
@Injectable()
@Component({
    selector: 'app-megamenu',
    templateUrl: './app.megamenu.component.html',
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppMegamenuComponent {

    constructor(public app: AppComponent,
                public session: SessionService
                ) {}

    logSession() {
        // console.log('loggedIn', this.session.loggedIn);
        // console.log('sess', this.session.sess);
        // console.log('nomVisiteur', this.session.nomVisiteur);
        // console.log('permAdmin', this.session.permAdmin);
        // console.log('idVisiteur', this.session.idVisiteur);
        // console.log('modeAdmin', this.session.modeAdmin);
        // console.log('superhero', this.session.superhero);
    }
}
