import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PagePrincipaleComponent} from './page-principale/page-principale.component';
import {PageLoginComponent} from './page-login/page-login.component';
import {PageLoggedInComponent} from './page-logged-in/page-logged-in.component';
import {LoggedInGuard} from '../routeGuards/logged-in.guard';
import {PageFormulaireComponent} from './page-formulaire/page-formulaire.component';
import {PageDepotFormulaireComponent} from './page-depot-formulaire/page-depot-formulaire.component';
import {AdminGuard} from '../routeGuards/admin.guard';
import {AdminLectGuard} from '../routeGuards/admin-lect.guard';
import {ChefAdminGuard} from '../routeGuards/chef-admin-guard.service';
import {SuperheroGuard} from '../routeGuards/superhero.guard';
import {AdulteSeulementGuard} from '../routeGuards/adulte-seulement.guard';
import {Age16OuPlusGuard} from '../routeGuards/age16-ou-plus.guard';
import {ParentGuard} from '../routeGuards/parent.guard';
import {DossierMembreResolver} from './resolvers/dossier-membre.resolver';
import {PageInitialiseeGuard} from '../routeGuards/page-initialisee.guard';
import {WrapperComponent} from './wrapper/wrapper.component';


export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'principale/membre'},
  {
    path: 'principale', component: PagePrincipaleComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'membre'},
      {path: 'membre', component: WrapperComponent},
      {path: 'admin', loadChildren: () => import('./components/activites/activites.module').then(m => m.ActivitesModule)}
    ]
  },
  {path: 'login', component: PageLoginComponent},
  {path: 'liens', redirectTo: 'liens/', pathMatch: 'full'},
  {
    path: 'liens/:mode',
    loadChildren: () => import('./liens/liens.module').then(m => m.LiensModule),
    canActivate: [PageInitialiseeGuard, LoggedInGuard, AdulteSeulementGuard]
  },
  {
    path: 'monDossier',
    loadChildren: () => import('./page-votre-dossier/page-votre-dossier.module').then(m => m.PageVotreDossierModule),
    canActivate: [LoggedInGuard, AdulteSeulementGuard],
    resolve: {
      data: DossierMembreResolver
    }
  },
  {
    path: 'autresRepondants',
    loadChildren: () => import('./page-autres-repondants/page-autres-repondants.module').then(m => m.PageAutresRepondantsModule),
    canActivate: [LoggedInGuard, ParentGuard]
  },
  {
    path: 'chgMdp',
    loadChildren: () => import('./page-chg-mdp/page-chg-mdp.module').then(m => m.PageChgMdpModule),
    canActivate: [LoggedInGuard]
  },
  {path: 'loggedIn', component: PageLoggedInComponent},
  {path: 'inscription', loadChildren: () => import('./page-inscription/page-inscription.module').then(m => m.PageInscriptionModule)},
  {path: 'perdumdp', loadChildren: () => import('./page-perdu-mdp/page-perdu-mdp.module').then(m => m.PagePerduMdpModule)},
  {path: 'formulaire', component: PageFormulaireComponent, canActivate: [Age16OuPlusGuard]},
  {path: 'arenas', loadChildren: () => import('./page-liste-arenas/page-liste-arenas.module').then(m => m.PageListeArenasModule)},
  {path: 'depot', component: PageDepotFormulaireComponent},
  {path: 'doublons', loadChildren: () => import('./doublons/doublons.module').then(m => m.DoublonsModule)},
  // {path: 'doublons', component: PageDetectionDoublonsComponent, canActivate: [AdminGuard]},
  // {path: 'doublons/un_doublon', component: PageUnDoublonComponent, canActivate: [AdminGuard]},
  {path: 'info', loadChildren: () => import('./page-aide/page-aide.module').then(m => m.PageAideModule)},
  {
    path: 'gestionEv',
    loadChildren: () => import('./page-gestion-evenements/page-gestion-evenements.module').then(m => m.PageGestionEvenementsModule),
    canActivate: [AdminLectGuard]
  },
  {path: 'rapports',
    loadChildren: () => import('./page-rapports/page-rapports.module').then(m => m.PageRapportsModule),
    canActivate: [AdminLectGuard]},
  {path: 'admin',
    loadChildren: () => import('./admin-page/admin-page.module').then(m => m.AdminPageModule),
    canActivate: [AdminGuard]},
  {path: 'admin/admins',
    loadChildren: () => import('./page-admins/page-admins.module').then(m => m.PageAdminsModule),
    canActivate: [AdminGuard]},
  {path: 'admin/avisCourriel',
    loadChildren: () => import('./page-courriels-avis/page-courriels-avis.module').then(m => m.PageCourrielsAvisModule),
    canActivate: [ChefAdminGuard]},
  {
    path: 'manuel',
    loadChildren: () => import('./page-entree-manuelle/page-entree-manuelle.module').then(m => m.PageEntreeManuelleModule),
    canActivate: [AdminGuard]
  },
  {path: 'rapports/depots',
    loadChildren: () => import('./page-liste-depots/page-liste-depots.module').then(m => m.PageListeDepotsModule),
    canActivate: [AdminGuard]},
  {path: 'rapports/depots/:debut',
    loadChildren: () => import('./page-liste-depots/page-liste-depots.module').then(m => m.PageListeDepotsModule),
    canActivate: [AdminGuard]},
  {path: 'rapports/depots/:debut/:fin',
    loadChildren: () => import('./page-liste-depots/page-liste-depots.module').then(m => m.PageListeDepotsModule),
    canActivate: [AdminGuard]},
  {
    path: 'rapports/probl',
    loadChildren: () => import('./page-formulaires-problem/page-formulaires-problem.module').then(m => m.PageFormulairesProblemModule),
    canActivate: [ChefAdminGuard]
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
  {
    path: 'suivisCas', loadChildren: () => import('./suivis-cas/suivis-cas.module').then(m => m.SuivisCasModule),
    canActivate: [ChefAdminGuard]
  },
  {path: 'tracage', loadChildren: () => import('./tracage/tracage.module').then(m => m.TracageModule), canActivate: [ChefAdminGuard]},
  {path: 'personnifier', loadChildren: () => import('./page-personnifier/page-personnifier.module').then(m => m.PagePersonnifierModule)},
  {path: 'code-ethique', redirectTo: 'code-ethique/0', pathMatch: 'full'},
  {
    path: 'code-ethique/:choixIdMembre',
    loadChildren: () => import('./page-info-documents/page-info-documents.module').then(m => m.PageInfoDocumentsModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'remplirFormulaire/:idDoc/:choixIdMembre',
    loadChildren: () => import('./page-remplir-formulaire/page-remplir-formulaire.module').then(m => m.PageRemplirFormulaireModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'documents', loadChildren: () => import('./gestion-documents/gestion-documents.module').then(m => m.GestionDocumentsModule),
    canActivate: [SuperheroGuard]
  }
  ,
  {
    path: 'versions',
    loadChildren: () => import('./notes-version/notes-version.module').then((m) => m.NotesVersionModule)
  },
  { path: 'importerHoraire',
    loadChildren: () => import('./importer-horaire/importer-horaire.module').then(m => m.ImporterHoraireModule),
    canActivate: [SuperheroGuard]
  },
  { path: 'config', loadChildren: () => import('./config/config.module').then(m => m.ConfigModule) ,
    canActivate: [SuperheroGuard]
  }
];

// export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
