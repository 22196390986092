import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StoreInterface} from '../app.module';
import {createSelector, Store} from '@ngrx/store';
import * as R from 'ramda';
import * as moment from 'moment';
import {setIdVisiteur} from '../../store/actions/session.actions';
import {BreadcrumbService} from '../breadcrumb.service';
import {PageService} from '../../services/page.service';
import {SessionService} from '../../services/session.service';
import {Subscription} from 'rxjs';
import {GrowlService} from '../../services/growl.service';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
@Component({
  selector: 'app-page-principale',
  templateUrl: './page-principale.component.html',
  styleUrls: ['./page-principale.component.sass'],
  providers: [
    TranslateService
  ]
})
export class PagePrincipaleComponent implements OnInit, OnDestroy {
  public nb = 10;
  public val;
  public idVisiteur;
  public anneeLimite;
  public subs: Subscription[] = [];
  public delaiExpire = false;
  public modeAdmin = false;
  public interdictionDepots = false;
  public dateReprise = null;
  public messageInterdictionDepot;
  
  constructor(
    public store: Store<StoreInterface>,
    public breadCrumb: BreadcrumbService,
    public pageServ: PageService,
    public session: SessionService,
    public growl: GrowlService,
    public router: Router,
    public san: DomSanitizer
  ) {
  }
  
  async ngOnInit() {
    
    await this.pageServ.pageInitialized();
    this.breadCrumb.setItems([]);
    this.subs.push;
    this.store.select(createSelector(R.prop('session'), R.pick(
      [
        'idVisiteur',
        'modeAdmin',
        'interdictionDepots',
        'dateReprise',
        'messageInterdictionDepots'
      ])))
      .subscribe(
        ({idVisiteur, modeAdmin, interdictionDepots, dateReprise, messageInterdictionDepots}) => {
          this.idVisiteur = idVisiteur;
          this.modeAdmin = modeAdmin;
          this.interdictionDepots = !!interdictionDepots;
          this.dateReprise = null;
          if (interdictionDepots === 2) {
            this.interdictionDepots = moment().isBefore(dateReprise);
            if (this.interdictionDepots) {
              try {
                this.dateReprise = moment(dateReprise).locale('fr').format('dddd D MMMM');
              } catch (e) {
                console.error(e.getMessage());
              }
            }
          }
          this.messageInterdictionDepot = this.san.bypassSecurityTrustHtml(messageInterdictionDepots);
          if (modeAdmin) {
            // console.log('navigate admin');
            this.router.navigate(['/principale', 'admin']);
          } else {
            // console.log('navigate principale')
            this.router.navigate(['/principale']);
          }
        }
      );
    this.anneeLimite = new Date().getFullYear() - 16;
    setTimeout(() => this.delaiExpire = true, 1000);
  }
  
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  
  set(val) {
    this.store.dispatch(setIdVisiteur({idVisiteur: val}));
  }
  
  clickLoggedIn(path) {
    // if (!this.session.idVisiteur) {
    //     this.growl.info('Identifiez-vous d\'abord');
    //     return;
    // }
    this.router.navigate(path);
  }
  
  toggleAdminMode() {
    this.session.toggleAdminMode();
    
  }
}
