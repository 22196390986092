import {emptyFormEvent, FormEvent} from './formulaires.reducer';
import {createReducer, on} from '@ngrx/store';
import {
  gestionConfirmerPresence,
  gestionDeplacerDepot,
  gestionEffaceEvenement,
  gestionReset,
  gestionSaveEvent,
  gestionSet, gestionUpdateEvent
} from '../actions/gestion.actions';
import * as R from 'ramda';
import produce from 'immer';

export interface GestionState {
  debutEv: string; // date debut ev chargés
  finEv: string; // date fin ev chargés
  evenements: FormEvent[];
  users: { [id: string]: User };
  depots: Depot[];
}

export interface User {
  id: string;
  prenom: string;
  nom: string;
  cell: string;
  courriel: string;
  anneeNaissance: number;
  permVerif: boolean;
  permCreerEv: boolean;
  permAdmin: boolean;
  permChefAdmin: boolean;
  superhero: boolean;
  typeDefaut: string;
}

export interface Depot {
  id: string;
  dateDepot: string;
  lieu: string;
  evenement: string;
  idInstance: string;
  idFormulaire: string;
  dateRempli: string;
  rempliPar: string;
  idMembre: string;
  presenceConf: string;
  dateEvent: string;
  finEvent: string;
  nom: string;
  nomRempliPar: string;
  nomResp: string;
  idResp: string; // responsable de l'événement où on dépose (si on dépose à ev)
  typeEv: string;
  descrEv: string;
  anneeNaissanceMembre: number;
  ageApproxMembre: number;
}

export const gestionInitialState: GestionState = {
  debutEv: null,
  finEv: null,
  evenements: [],
  users: {},
  depots: []
};

export const emptyUser = {
  id: null,
  prenom: null,
  nom: null,
  cell: null,
  courriel: null,
  anneeNaissance: null,
  permVerif: null,
  permCreerEv: null,
  permAdmin: null,
  permChefAdmin: null,
  superhero: null,
  typeDefaut: null
};

// tslint:disable-next-line:variable-name
const _gestionReducer = createReducer(
  gestionInitialState,
  on(gestionReset, (state) => gestionInitialState),
  
  on(gestionSet, (state, {evenements, users, debutEv, finEv, depots}) => {
    return {
      evenements,
      users,
      debutEv,
      finEv,
      depots
    };
  }),
  
  on(gestionSaveEvent, (state, {data}) => {
    return produce(state, (draft: GestionState) => {
      data = R.pick(R.keys(emptyFormEvent), data);
      const index = R.findIndex(R.propEq('id', data.id), state.evenements);
      
      if (index !== -1) {
        const initialDate = R.path(['evenements', data.id, 'date'], draft);
        draft.evenements[index] = data;
        if (initialDate !== data.date) {
          draft.evenements = R.sortBy(R.prop('date'), draft.evenements);
        }
      } else {
        const lastDate = R.pipe(
          R.prop('evenements'),
          R.last,
          R.prop('date')
        )(draft);
        draft.evenements.push(data);
        if (lastDate && data.date < lastDate) {
          draft.evenements = R.sortBy(R.prop('date'), draft.evenements);
        }
      }
    });
  }),
  
  on(gestionUpdateEvent, (state, {data}) => {
    return produce(state, (draft: GestionState) => {
      data = R.pick(R.keys(emptyFormEvent), data);
      const index = R.findIndex(R.propEq('id', data.id), draft.evenements);
      if (index === -1) {
        console.error('activité introuvable #' + data.id);
        return;
      }
      const initialDate = draft.evenements[index].date;
      console.log('data', R.clone(data))
      console.log('initial index ' + index, R.clone(draft.evenements[index]))
      draft.evenements[index] = R.mergeRight(draft.evenements[index], data);
      console.log('final', R.clone(draft.evenements[index]));
      if (initialDate !== draft.evenements[index].date) {
        draft.evenements = R.sortBy(R.prop('date'), draft.evenements);
      }
    })
  }),
  
  
  on(gestionEffaceEvenement, (state, {id}) => {
    return produce(state, (draft: GestionState) => {
      const index = R.pipe(
        R.prop('evenements'),
        R.findIndex(R.propEq('id', id))
      )(draft);
      if (index > -1) {
        draft.evenements = R.remove(index, 1, draft.evenements);
      }
    });
  }),
  on(gestionConfirmerPresence, (state, {idDepot, presenceConf}) =>
    produce(state, (draft: GestionState) => {
      const index = R.findIndex(R.propEq('id', idDepot), draft.depots);
      if (index > -1) {
        draft.depots = R.adjust(index, R.assoc('presenceConf', presenceConf))(draft.depots);
      }
    })
  ),
  on(gestionDeplacerDepot, (state, {
    idDepot,
      presenceConf,
      idEvent,
      dateEvent,
      finEvent,
      typeEv,
      idResp
  }) =>
    produce(state, (draft: GestionState) => {
      const index = R.findIndex(R.propEq('id', idDepot), draft.depots);
      if (index > -1) {
        const evenement = idEvent;
        draft.depots = R.adjust(index,
          R.mergeLeft(
            {
              presenceConf,
              evenement,
              dateEvent,
              finEvent,
              typeEv,
              idResp
            }
          )
          
        )(draft.depots);
      }
    })

  )
);

// tslint:disable-next-line:no-shadowed-variable
export function gestionReducer(state, action) {
  return _gestionReducer(state, action);
}
