import { Component, OnInit } from '@angular/core';
import {SessionService} from '../../services/session.service';
import { Router} from '@angular/router';
import {GrowlService} from '../../services/growl.service';
import {FormService} from '../../services/form.service';
import {XhrService} from '../../services/xhr.service';

@Component({
  selector: 'app-page-formulaire',
  templateUrl: './page-formulaire.component.html',
  styleUrls: ['./page-formulaire.component.sass']
})
export class PageFormulaireComponent implements OnInit {
  public rempliPour;
  public idVisiteur;
  
  public rep1: boolean;
  public rep2: boolean;
  public rep3: boolean;
  public rep4: boolean;
  
  constructor(
    public session: SessionService,
    public router: Router,
    public growl: GrowlService,
    public formServ: FormService,
    public xhr: XhrService
  ) {
    
 
  
  }

  ngOnInit(): void {
    this.idVisiteur = this.session.idVisiteur;
    if (!this.idVisiteur) {
      this.growl.error('Vous devez vous identifier');
      this.router.navigate(['/login']);
    }
    if (!this.formServ.idMembreForm) {
      // console.log('>>>>>>>>>>>' + this.formServ.idMembreForm);
      // this.growl.error('Formulaire rempli pour qui--------?');
      this.router.navigate(['/login']);
    }
    // console.log('fin init form');
    
    
  }
  complet() {
    
    return this.rep1 !== undefined && this.rep2 !== undefined && this.rep3 !== undefined && this.rep4 !== undefined;
  }
  
  setResults(rep1, rep2, rep3, rep4) {
    this.rep1 = rep1;
    this.rep2 = rep2;
    this.rep3 = rep3;
    this.rep4 = rep4;
    
  }
  
  async soumettre() {
    if (!this.complet()) {
      return;
    }
    const succes = await this.formServ.submitForm([this.rep1 ? 1:0, this.rep2 ? 1:0, this.rep3 ? 1:0, this.rep4 ? 1:0]);
    if (succes) {
      this.formServ.clrIds();
      this.router.navigate(['/']);
    }
  
  }

}
