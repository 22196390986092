import {Component, Injectable, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../../app.module';
import * as R from 'ramda';
import * as moment from 'moment';
import {FormService} from '../../../services/form.service';
import {FormDepot, FormInstance} from '../../../store/reducers/formulaires.reducer';
import {Subscription} from 'rxjs';
import {PageService} from '../../../services/page.service';
import {ExtendedRefMembre} from '../etat-forms/etat-forms.component';
import {SessionService} from '../../../services/session.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import {MesureActive, RefMembre} from '../../../store/reducers/dossiersLies.reducer';

@Injectable()
@Component({
  selector: 'app-form-status',
  templateUrl: './form-status.component.html',
  styleUrls: ['./form-status.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class FormStatusComponent implements OnInit, OnDestroy {
  
  @Input() dossier: ExtendedRefMembre;
  @Input() idForm;
  @Input() codesEth = [];
  
  public dureeValidite: number;
  public joursCharges;
  public forms;
  public refMembres;
  public dataMembre;
  
  public derniereInstance;
  public derniereInstanceValide = false;
  public dateDerniereInstance: any | moment.Moment;
  public limiteValidite;
  public formDesc: string;
  public msgExpire: string;
  public dernierDepotMsg;
  public misAJourDepuisDepot = false;
  private subs: Subscription[] = [];
  public restrictionSante;
  
  public depotInterdit: boolean;
  public depotInterditRaison: string;
  faExclamationTriangle = faExclamationTriangle;
  
  constructor(
    public store: Store<StoreInterface>,
    public formServ: FormService,
    public pageServ: PageService,
    public session: SessionService
  ) {
  }
  
  get choisi() {
    return this.formServ.choisi(this.dossier.id);
  }
  
  set choisi(val) {
    if (val) {
      this.formServ.choisir(this.dossier.id);
    } else {
      this.formServ.dechoisir(this.dossier.id);
    }
    
  }
  
  ngOnInit(): void {
    this.subs.push(
      this.store.select(createSelector(R.prop('formulaires'), forms => {
        return {
          dureeValidite: R.path(['ref', 'dureeValidite'], forms),
          joursCharges: R.path(['ref', 'joursCharges'], forms),
          forms: R.path(['ref', 'forms'], forms),
          refMembres: R.path(['ref', 'dataMembres'], forms),
          dataMembre: R.pathOr({}, ['dataMembres', this.dossier.id], forms),
          lieux: R.pathOr({}, ['ref', 'formLieux'], forms),
          events: R.pathOr({}, ['ref', 'events'], forms)
        };
      })).subscribe(({
                       dureeValidite,
                       joursCharges,
                       forms,
                       refMembres,
                       dataMembre,
                       lieux,
                       events
                     }) => {
        if (!dureeValidite) {
          return;
        }
        this.dureeValidite = dureeValidite;
        this.joursCharges = joursCharges;
        this.forms = this.idForm ? R.filter(R.propEq('id', this.idForm), forms) : forms;
        this.refMembres = refMembres;
        this.dataMembre = dataMembre;
        const derniereInstance: FormInstance = R.pathOr(null, ['instances', 0], dataMembre);
        this.derniereInstance = derniereInstance;
        // this.restrictionSante = derniereInstance && derniereInstance.restriction;
        this.derniereInstanceValide = (this.derniereInstance && moment().diff(this.derniereInstance.date, 'm') < dureeValidite);
        this.dateDerniereInstance = (this.derniereInstance ? moment(this.derniereInstance.date).format('dddd HH:mm') : '?');
        this.limiteValidite = (this.derniereInstance ?
          moment(this.derniereInstance.date).add(this.dureeValidite, 'm').format('dddd HH:mm')
          : '?');
        this.msgExpire = this.derniereInstance ?
          moment.duration(moment(this.derniereInstance.date).add(this.dureeValidite, 'm').diff(moment())).humanize(true)
          : 'pas de formulaire rempli à ce jour';
        this.formDesc = R.pathOr('?', [this.idForm, 'desc'], this.forms);
        const dernierDepot: FormDepot = R.pathOr(null, ['depots', 0], dataMembre);
        const nonSpecifie = 'endroit non spécifié';
        if (dernierDepot) {
          let idDernDep = dernierDepot.lieu;
          if (!idDernDep) { // si pas de lieu, alors un ev
            idDernDep = R.pathOr(null, [dernierDepot.evenement, 'lieu'], events);
          }
          const endroit = !idDernDep ? nonSpecifie : R.pathOr(nonSpecifie, [idDernDep, 'nom'], lieux);
          this.dernierDepotMsg = `Déposé à ${endroit} ` + moment.duration(moment(dernierDepot.date).diff(moment())).humanize(true);
          // *********** vérifier si dernier dépot = dernière soumission
          if (derniereInstance) {
            if (dernierDepot.idInstance !== derniereInstance.id) {
              this.misAJourDepuisDepot = true;
            }
          }
        } else {
          this.dernierDepotMsg = 'Pas déposé';
        }
        
        
      }),
      
      // this.store.select(createSelector(R.prop('dossiersLies'), R.path(['ref', this.dossier.id]))).subscribe((dossierLie: RefMembre) => {
      //   this.depotInterdit = dossierLie.depotInterdit;
      //   this.depotInterditRaison = dossierLie.depotInterditRaison;
      // })
    
      this.store.select(createSelector(R.path(['dossiersLies', 'mesures']), R.filter(R.propEq('idMembre', this.dossier.id)))).subscribe((mesuresMembre: MesureActive[]) => {
        this.depotInterdit = !!mesuresMembre.length;
        this.depotInterditRaison = this.depotInterdit ? mesuresMembre[0].message : '';
      })
    
    );
    
  }
  
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  
  
  valide() {
    if (!this.derniereInstance || this.derniereInstance.restriction) {
      return false;
    }
    const val = moment().diff(this.derniereInstance.date, 'm') < this.dureeValidite;
    if (!val) {
      this.formServ.dechoisir(this.dossier.id);
    }
    return val;
  }
  
  nom(id) {
    const data = R.propOr(null, this.refMembres, id);
    if (!data) {
      return '?';
    }
    return data.prenom + ' ' + data.nom;
  }
  
  remplirForm() {
    this.formServ.remplirForm(this.dossier);
  }
  
  statusSeverity(code) {
    if (code === 'J' || code === 'E') {
      return 'success';
    }
    return 'info';
  }
}
