import { createReducer, on } from '@ngrx/store';
import * as R from 'ramda';
import {
  clearAllValidationsCourriels,
  clearSingleValidationCourriel, updateMultiValidationsCourriels,
  updateValidationCourriel
} from '../actions/validationCourriels.actions';



export interface ValidationCourriels {
  [courriel: string]: StatutCourriel;
}

export interface StatutCourriel {
  courriel: string;
  date: string;
  dateDebloque: string;
  utilise: boolean;
}

export const validationCourrielsInitialState: ValidationCourriels = {
  
};


// tslint:disable-next-line:variable-name
const _validationCourrielsReducer = createReducer(
  validationCourrielsInitialState,
  on(clearAllValidationsCourriels, (state) => ({})),
  
  on(clearSingleValidationCourriel, (state, {courriel}) => {
    courriel = R.toLower(courriel);
    return R.dissoc(courriel, state);
  }),
  
  on(updateValidationCourriel, (state, {courriel, date, dateDebloque, utilise})=> {
    courriel = R.toLower(courriel);
    return R.assoc(courriel, {courriel, date, dateDebloque, utilise}, state);
  }),
  
  on(updateMultiValidationsCourriels, (state, {statuts}) => {
    const lower = R.over(R.lensProp('courriel'), R.toLower);
    const indexedStatuts = R.pipe(
      R.map(lower),
      R.indexBy(R.prop('courriel'))
    )(statuts);
    return R.mergeRight(state, indexedStatuts);
  })
);

// tslint:disable-next-line:no-shadowed-variable
export function validationCourrielsReducer(state, action) {
  return _validationCourrielsReducer(state, action);
}
