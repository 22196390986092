<app-page-std [pathRetour]="null" fadein>

    <header>Page principale
        <div *ngIf="modeAdmin" style="font-size: 1rem;margin-top: 0"><br>Mode administratif</div>
    </header>

    <app-label-component label="page-principale" [overlay]="true">
        <ng-container *ngIf="session.idVisiteur;else noLogin">
            Nom: {{session.nomVisiteur}} #{{session.idVisiteur}} <br>
            Resp: <span *ngIf="session.idResp">{{session.nomResp}} #{{session.idResp}}</span><br>
            Admin: {{session.superhero ? 'superhero' : session.permChefAdmin ? 'admin +' : session.permAdmin ? 'Admin' : session.permVerif ? 'Vérif' : 'aucun'}}

        </ng-container>
        <ng-template #noLogin>Pas de session ouverte</ng-template>
    </app-label-component>
    <div>
        <ng-container *ngIf="!modeAdmin; else mode_admin">

            <p-panel [toggleable]="true"
                     styleClass="round-top"
                     *ngIf="!interdictionDepots"
            >
                <ng-template pTemplate="header">Info Covid</ng-template>
                <p>Remplissez votre formulaire d'auto-évaluation de votre état de santé avant de vous présenter à
                    toute activité des Braves, qu'il s'agisse d'essais, de pratiques, de matchs, et que vous y
                    assistiez en tant que joueur, accompagnateur, officiel d'une équipe, ou à tout autre titre. </p>
                <p>
                    Un formulaire doit être rempli au maximum <b>3 heures</b> avant d'être déposé (électroniquement, en
                    utilisant le présent programme) en arrivant à l'activité.
                </p>

                Un membre ayant 16 ans ou plus à son anniversaire cette année peut remplir son propre formulaire de
                santé. Toutefois, un parent
                doit enregistrer tout joueur d'âge mineur. <a class="cursor-pointer" (click)="info16_18.show($event)">Voir
                ici comment procéder</a> pour donner plus d'autonomie à un membre
                entre 16 et 18 ans.
                <p-fieldset [toggleable]="true"
                            [collapsed]="true"
                            *ngIf="session.idVisiteur && !session.idResp && session.isAdulte"
                            legend="Ajouter un jeune ou un répondant"
                >
                    <ng-container *ngIf="delaiExpire">
                        <p>Un jeune peut avoir plusieurs adultes susceptibles de l'accompagner à divers moments, qui
                            devront pouvoir remplir et déposer son formulaire.
                        </p>
                        <p>
                            Dans ce cas, chaque parent doit s'enregistrer chacun de son côté. Un seul d'entre eux
                            fournit la
                            liste des enfants
                            en s'enregistrant. Ensuite ce parent désigne son conjoint comme répondant additionnel. Le
                            conjoint,
                            de son côté, doit désigner le premier parent comme "fournisseur" d'enfants pour lui. Quand
                            les
                            deux
                            adultes
                            se sont désignés mutuellement, chacun a accès aux enfants.
                        </p>
                        <div class="text-left border-300 border-1 shadow-1 surface-50 border-round p-2 m-1">
                            <p><a href="#" [routerLink]="['/autresRepondants']">Cliquez ici pour offrir à votre conjoint
                                (ou
                                d'autres)</a>
                                accès aux enfants que vous avez enregistrés.</p>
                            <p><a href="#" [routerLink]="['/liens', 'ajout']">Cliquez ici pour demander à votre conjoint
                                (ou
                                une
                                autre
                                personne)</a> accès aux enfants qu'il a enregistrés <b>ou pour ajouter un enfant non
                                encore
                                enregistré</b></p>

                        </div>
                    </ng-container>
                </p-fieldset>
                <p-panel [showHeader]="false" *ngIf="session.loggedIn">
                    <div class="text-sm">Les marqueurs suivants identifient les codes d'éthique et formulaires de
                        consentement signés:
                    </div>
                    <div class="flex justify-content-around text-sm">
                        <div>
                            <p-badge severity="success" value="J" styleClass="shadow-2"></p-badge>
                            Joueur
                        </div>
                        <div>
                            <p-badge severity="info" value="P" styleClass="shadow-2"></p-badge>
                            Parent
                        </div>
                        <div>
                            <p-badge severity="success" value="E" styleClass="shadow-2"></p-badge>
                            Entraîneur
                        </div>
                        <div>
                            <p-badge severity="info" value="A" styleClass="shadow-2"></p-badge>
                            Administrateur
                        </div>
                        <div>
                            <p-badge severity="danger" value="!" styleClass="shadow-2"></p-badge>
                            Aucun
                        </div>

                    </div>
                </p-panel>
            </p-panel>
            <p-panel *ngIf="interdictionDepots">
                <div class="text-center" *ngIf="dateReprise">Dépôt des formulaires de santé suspendu jusqu'au {{dateReprise}}</div>
                <div class="text-center m-5 text-xl" [innerHTML]="messageInterdictionDepot"></div>
            </p-panel>
            <div *ngIf="session.permVerif" class="p-grid p-col-12 p-justify-around">
                <div class="p-g-12 p-md-4">
                    <button pButton [label]="modeAdmin ? 'Retour au mode normal' : 'Mode admin disponible'"
                            (click)="toggleAdminMode()"></button>
                </div>
            </div>


            <ng-container *ngIf="!interdictionDepots">
                <app-etat-forms *ngIf="session.idVisiteur; else pas_de_session_ouverte"></app-etat-forms>
            </ng-container>

        </ng-container>

    </div>
    <ng-template #mode_admin>

        <div style="text-align: left"><a class="hover_ptr" (click)="session.toggleAdminMode()">Quitter mode admin.</a>
        </div>
        <router-outlet></router-outlet>
<!--        <app-admin-menu-page></app-admin-menu-page>-->
    </ng-template>
</app-page-std>

<ng-template #pas_de_session_ouverte>
    <div>
        <div style="font-size:1.1rem; margin: 1rem">
            <b>Vous devez vous identifier</b> <br>pour remplir votre formulaire et le déposer en arrivant à une
            activité.
        </div>
        <p>Pour vous identifier, <a [routerLink]="['/login']">cliquez sur le bonhomme rouge <i class="fa fa-user"
                                                                                               style="color: red"></i></a>
            dans le coin supérieur gauche</p>
        <p>Sur un petit écran, il peut être nécessaire de cliquer sur l'icône de menu (<i class="fa fa-align-left"></i>)
            (pour faire apparaître le bonhomme)</p>
    </div>
    <div style="margin-top: 2rem;text-align: center">
        <button class="login" pButton label="Ouvrez d'abord une session!" [routerLink]="['/login']"></button>
    </div>
    <div style="margin: 2rem 0">
        <a [routerLink]="['/info']">Pour vous orienter lors d'une première visite, allez faire un tour ici!</a>
    </div>


</ng-template>

<p-overlayPanel
        #info16_18
        appendTo="body"
        [style]="{'maxWidth': '40rem'}"
>
    <div class="text-xl">
        Remplissage du formulaire de santé par jeune de 16 à 18 ans
    </div>
    <div class="mt-3">
        Ceci concerne un jeune enregistré par un parent. Pour qu'il lui soit possible de
        remplir lui-même son formulaire de santé, le jeune doit pouvoir ouvrir une session.
        Procéder comme suit:
        <ul>
            <li>
                L'adulte qui a enregistré le jeune doit ouvrir une session et cliquer
                sur le bonhomme vert dans le coin supérieur gauche de l'écran. (ouvrir le menu au besoin)
            </li>
            <li>
                Dans le menu, choisir 'Réglages' puis 'Vos jeunes'
            </li>
            <li>
                Une fiche sera affichée pour chaque jeune auquel vous avez accès. <b>Vous ne pourrez
                modifier que le dossier d'un jeune que vous avez vous-même enregistré</b>.
            </li>
            <li>
                Cliquez sur la fiche du jeune, le symbole <i class="fas fa-bars"></i> apparaîtra, donnant
                accès à un menu.
            </li>
            <li>
                Choisissez 'Modifier dossier' et inscrivez au dossier du jeune son adresse de courriel personnelle.
            </li>
            <li>
                Fermez votre session, et laissez le jeune en ouvrir une. Comme il n'a pas de mot de passe,
                qu'il clique sur "J'ai perdu mon mot de passe". On lui demandera un mot de passe et son adresse
                de courriel, de même qu'un code envoyé à cette adresse.
            </li>
            <li>
                Le jeune pourra dorénavant remplir son propre formulaire de santé pour chaque activité.
            </li>
        </ul>
        <div class="text-center my-3">
            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-outlined" (click)="info16_18.hide()"></button>
        </div>
    </div>
</p-overlayPanel>
