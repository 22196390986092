import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {XhrService} from './xhr.service';
import {SessionService} from './session.service';
import {GrowlService} from './growl.service';
import {PageService} from './page.service';
import * as R from 'ramda';
import {Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import {
  formulairesUpdateDepotsMembres,
  formulairesUpdateInstancesMembres,
  formulairesUpdateRef
} from '../store/actions/formulaires.actions';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public idMembreForm;
  public nomMembreForm;
  public idsChoisisPourDepot = []; // *************** ids membres, et non formulaire
  
  constructor(
    public router: Router,
    public xhr: XhrService,
    public session: SessionService,
    public growl: GrowlService,
    public pageServ: PageService,
    public store: Store<StoreInterface>
  ) { }
  
  
  remplirForm({id, nom}) {
    this.idMembreForm = id;
    this.nomMembreForm = nom;
    
    this.router.navigate(['/formulaire']);
  }
  
  async submitForm(reponses: number[]) {
    const data = await this.xhr.post('gestion_forms', 'soumettre_formulaire', {
      idPar: this.session.idVisiteur,
      idPour: this.idMembreForm,
      data: reponses,
      idForm: this.pageServ.idForm
    }, {noThrow: true});
    
    if (data.result) {
      this.store.dispatch(formulairesUpdateInstancesMembres(data));
      this.growl.info('Formulaire rempli', `N'oubliez pas de le déposer à l'événement auquel vous allez participer!`);
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  clrIds() {
    this.idMembreForm = null;
    this.nomMembreForm = null;
  }
  
  choisir(id) {
    if ( !this.choisi(id) ) {
      this.idsChoisisPourDepot = R.append(id, this.idsChoisisPourDepot);
    }
  }
  dechoisir(id) {
    this.idsChoisisPourDepot = R.without([id], this.idsChoisisPourDepot);
  }
  choisi(id) {
    return R.contains(id, this.idsChoisisPourDepot);
  }
  
  async updateFormRef() {
    const data = await this.xhr.post('gestion_forms', 'get_ref', {}, {noThrow: true});
    if (data.result) {
      this.store.dispatch(formulairesUpdateRef(data));
      this.growl.info('Info de référence mise à jour');
    } else {
      this.growl.xhr_error(data);
    }
  }
  
  async deposer({evChoisi, lieu}) {
    const data = await this.xhr.post('gestion_forms', 'deposer_formulaires', {
      idsMembres: this.idsChoisisPourDepot,
      idEvent: evChoisi,
      idLieu: lieu
    }, {noThrow: true});
    if (data.result) {
      this.store.dispatch(formulairesUpdateDepotsMembres(data));
      this.growl.info('OK');
      return true;
    } else {
      // console.log('retuen false', data);
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  clrChoix() {
    this.idsChoisisPourDepot = [];
  }
  
  async unFormulaireDejaSoumis(idMembre) {
    const data = await this.xhr.post('gestion_forms', 'formulaires_soumis', {id: idMembre}, {noThrow: true});
    if (data.result) {
      return data.soumis;
    }
    this.growl.xhr_error(data);
    return null;
  }
}

