import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import {SessionService} from '../services/session.service';
import {StoreInterface} from './app.module';
import {Store} from '@ngrx/store';
import {debugOff, debugOn} from '../store/actions/session.actions';

import {faBug} from '@fortawesome/free-solid-svg-icons';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {faAlignLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  
  fasBug = faBug;
  ellipsis = faEllipsisV;
  alignLeft = faAlignLeft;
  
  constructor(
    public app: AppComponent,
    public session: SessionService,
    public store: Store<StoreInterface>
  ) {
  }
  
  toggleDebug() {
    if (this.session.debug) {
      this.store.dispatch(debugOff());
    } else {
      this.store.dispatch(debugOn());
    }
  }
}
