/**
 * Created by micra_000 on 2016-05-12.
 */
import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

// declare function require(str: string): any;

// declare let $: JQueryStatic;
import * as R from 'ramda';

@Injectable()
export class GrowlService {
  static nextId = 0;
  public msgs: Message[] = [];
  public growlServiceId;

  constructor(
    public msgServ: MessageService
  ) {
    this.growlServiceId = GrowlService.nextId++;
  }

  info(title: string, info?: string, key?: string) {
    this.msg('info', title, info, key);

  }

  warning(title: string, info?: string, key?: string) {
    this.msg('warn', title, info, key);
  }

  error(title: string, info?: string, key?: string) {
    this.msg('error', title, info, key);
  }

  msg(severity: string, title: string, info?: string, key?: string) {
    // this.msgs.push({severity, summary: title, detail: info || ''});
    // this.msgs = this.msgs.slice(0);
    // console.log('key', key);
    this.msgServ.add({severity, summary: title, detail: info || '', life: 5000, key});
  }

  xhr_error(data: any, key = null) {
    // let toType = (obj) => {
    //   return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
    // };
    if (typeof data === 'object') {
      if (data.msg && !data.summary) {
        data.summary = data.msg;
      }
      if (data.ref && !data.detail) {
        data.detail = data.ref;
      }
    }

    if (typeof(data) === 'string') {
      data = {summary: data};
    } else if (data instanceof SyntaxError) {
      data = {summary: 'SyntaxError', detail: data.message.substr(0, 100) };
    } else if (!R.is(Object, data)) {
      data = {summary: 'erreur'};
    }

    if (data) {

      const msg = R.mergeRight(R.pick(['summary', 'detail'], data), {severity: 'error', life: 5000});
      if (key) {
        msg.key = key;
        console.log('key = ', key)
      }
      this.msgServ.add(msg);
      
      // this.msgs = R.append(msg, this.msgs);
      return msg;
    }
    return false;
  }

  xhr_error_msg(data: object, array: Array<{}>, tr?) {
    const msg = this.xhr_error(data);
    if (msg) {
      array.push(msg);
    }
  }

  clear() {
    // this.msgs.splice(0, this.msgs.length);
    this.msgServ.clear();
  }

  onClose(ev: {message: Message}) {
    if (!ev || !ev.message) {
      return;
    }
    const pos = this.msgs.indexOf(ev.message);
    if (pos > -1) {
      this.msgs.splice(pos, 1);
    }
  }
  
  
}
