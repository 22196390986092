<div class="user-profile">
    <div class="text-xs" style="float: left"
         [class.opacity-30]="!pageServ.versionServeur || pageServ.versionServeur == version"
    >v.{{version}}
        <span style="color:red" class="ml-1" *ngIf="pageServ.versionServeur && pageServ.versionServeur != version"> <fa-icon
                [icon]="faExclamationTriangle"
        ></fa-icon> {{pageServ.versionServeur}} dispo.</span>

    </div><br>
    <a href="#" (click)="onProfileClick($event)" id="sidebar-profile-button">

        <ng-container *ngIf="sessionServ.loggedIn; else ouvrirSession">
<!--            <i *ngIf="!sessionServ.modeAdmin"  class="fas fa-user" style="color: green;font-size:4rem"></i>-->
<!--            <i *ngIf="sessionServ.modeAdmin"  class="fas fa-user-cog" style="color: coral;font-size:4rem"></i>-->
            <fa-icon [icon]="sessionServ.modeAdmin ? faUserCog : faUser"
                     [styles]="{color: sessionServ.modeAdmin ? 'coral' : 'green'}"
                     size="4x"
            ></fa-icon>
            <span style="display:block" class="sidebar-profile-name">{{sessionServ.nomVisiteur}}</span>
        </ng-container>
        <ng-template #ouvrirSession>
<!--            <i class="fas fa-user" style="color: red;font-size:4rem"></i>-->
            <fa-icon [icon]="faUser" [styles]="{color: 'red'}" size="4x"></fa-icon>
            <span style="display:block" class="sidebar-profile-name">Vous n'êtes pas reconnu!</span>
        </ng-template>

    </a>

    <ul  id="sidebar-usermenu" class="usermenu"
        [ngClass]="{'usermenu-active':app.usermenuActive}"
        [@menu]="app.isSlim()? app.usermenuActive ? 'visible' : 'hidden' :
                app.usermenuActive ? 'visibleAnimated' : 'hiddenAnimated'">
        <ng-container *ngIf="!sessionServ.loggedIn; else menuLoggedIn">
            <li #ouvrir_session [ngClass]="{'menuitem-active':app.activeProfileItem === ouvrir_session}">
                <a href="#" (click)="onProfileItemClick($event,ouvrir_session)" [routerLink]="['./login']">
<!--                    <i class="fa fa-fw fa-user" style="color: green"></i>-->
                    <fa-icon [icon]="faUser" [styles]="{color: 'green'}" [fixedWidth]="true"></fa-icon>
                    <span class="topbar-item-name">Ouvrez une session</span>
                </a>
            </li>


        </ng-container>

    </ul>
    <ng-template #menuLoggedIn>

            <li #fermer_session [ngClass]="{'menuitem-active':app.activeProfileItem === fermer_session}">
                <a href="#" (click)="onProfileItemClick($event,fermer_session);fermerSession()">
<!--                    <i class="fas fa-fw fa-user-slash" style="color: red"></i>-->
                    <fa-icon [icon]="faUserSlash" [styles]="{color: 'red'}" [fixedWidth]="true"></fa-icon>
                    <span class="topbar-item-name">Fermer votre session</span>
                </a>
            </li>

            <li *ngIf="sessionServ.permVerif
"                #toggle_admin [ngClass]="{'menuitem-active':app.activeProfileItem === toggle_admin}">
                <a href="#" (click)="onProfileItemClick($event,toggle_admin);sessionServ.toggleAdminMode()">
<!--                    <i class="fas fa-fw" [class.fa-user-cog]="!sessionServ.modeAdmin" [class.fa-user]="sessionServ.modeAdmin"></i>-->
                    <fa-icon [icon]="sessionServ.modeAdmin ? faUserCog : faUser" [fixedWidth]="true"></fa-icon>
                    <span class="topbar-item-name">{{!sessionServ.modeAdmin? 'Mode -> administrateur' : 'Mode -> visiteur'}}</span>
                </a>
            </li>
            <li #settings [ngClass]="{'menuitem-active':app.activeProfileItem === settings}">
                <a href="#" (click)="onProfileItemClick($event,settings)">
<!--                    <i class="fa fa-fw fa-cog"></i>-->
                    <fa-icon [icon]="faUserCog" [fixedWidth]="true"></fa-icon>
                    <span class="topbar-item-name">Réglages</span>
<!--                   <i class="layout-menuitem-toggler fa fa-fw fa-angle-down"></i>-->
                    <fa-icon [icon]="faAngleDown" [fixedWidth]="true" class="layout-menuitem-toggler"></fa-icon>
                </a>
                <ul>
                    <ng-container *ngIf="!sessionServ.modeAdmin">
                        <li role="menuitem">
                            <a href="#" (click)="onProfileSubItemClick($event)" [routerLink]="['/monDossier']">
<!--                                <i class="fas fa-fw fa-user-friends"></i>-->
                                <fa-icon [icon]="faUserEdit" [fixedWidth]="true"></fa-icon>
                                <span>Votre dossier</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" (click)="onProfileSubItemClick($event)" [routerLink]="['/liens']">
<!--                                <i class="fas fa-fw fa-user-friends"></i>-->
                                <fa-icon [icon]="faUserFriends" [fixedWidth]="true"></fa-icon>
                                <span>Vos jeunes</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" (click)="onProfileSubItemClick($event)" [routerLink]="['/autresRepondants']">
<!--                                <i class="fas fa-fw fa-user-friends"></i>-->
                                <fa-icon [icon]="faUserPlus" [fixedWidth]="true"></fa-icon>
                                <span>Répondants additionnels</span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="sessionServ.superhero && sessionServ.modeAdmin && sessionServ.local">
                        <li role="menuitem" *ngIf="sessionServ.superhero && sessionServ.modeAdmin">
                            <a href="#" (click)="onProfileSubItemClick($event);creer_evs()">
<!--                                <i class="fas fa-fw fa-calendar-alt"></i>-->
                                <fa-icon [icon]="faCalendarAlt" [fixedWidth]="true"></fa-icon>
                                <span>Créer évem. aléatoires</span>

                            </a>
                        </li>
                        <li role="menuitem" >
                            <a href="#" (click)="onProfileSubItemClick($event);effacer_evs(false)">
<!--                                <i class="far fa-fw fa-trash-alt"></i>-->
                                <fa-icon [icon]="faTrashAlt" [fixedWidth]="true"></fa-icon>
                                <span>Effacer ev.al. sans soum.</span>
                            </a>
                        </li>
                        <li role="menuitem">
                            <a href="#" (click)="onProfileSubItemClick($event);effacer_evs(true)">
<!--                                <i class="fas fa-fw fa-trash-alt"></i>-->
                                <fa-icon [icon]="faTrashAlt" [fixedWidth]="true"></fa-icon>
                                <span>Effacer tous év. aléatoires</span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="sessionServ.superhero">
                        <li role="menuitem">

                            <a class="cursor-pointer" (click)="toggleDebug()">
<!--                                <i class="fas fa-bug"></i>-->
                                <fa-icon [icon]="faBug" [fixedWidth]="true"></fa-icon>
                                Debug {{sessionServ.debug ? 'OFF' : 'ON'}}
                            </a>
                        </li>

                    </ng-container>
                    <ng-container *ngIf="sessionServ.loggedIn">
                        <li role="menuitem">
                            <a href="#" (click)="onProfileSubItemClick($event);" [routerLink]="['/chgMdp']">
<!--                                <i class="fas fa-fw fa-key"></i>-->
                                <fa-icon [icon]="faKey" [fixedWidth]="true"></fa-icon>
                                <span>Changer mot de passe</span>
                            </a>
                        </li>
                    </ng-container>
                    <ng-container *ngIf="sessionServ.superhero">
                        <li role="menuitem">

                            <a class="cursor-pointer" (click)="setNoVersion()">
                                <!--                                <i class="fas fa-bug"></i>-->
                                <fa-icon [icon]="faStamp" [fixedWidth]="true"></fa-icon>
                                Régler no de version
                            </a>
                        </li>

                    </ng-container>
                    <ng-container *ngIf="sessionServ.superhero">
                        <li role="menuitem">

                            <a class="cursor-pointer" [routerLink]="['/importerHoraire']">
                                <!--                                <i class="fas fa-bug"></i>-->
                                <fa-icon [icon]="faUpload" [fixedWidth]="true"></fa-icon>
                                Importer horaire
                            </a>
                        </li>

                    </ng-container>
                    <ng-container *ngIf="sessionServ.superhero">
                        <li role="menuitem">

                            <a class="cursor-pointer" [routerLink]="['/config']">
                                <!--                                <i class="fas fa-bug"></i>-->
                                <fa-icon [icon]="faCogs" [fixedWidth]="true"></fa-icon>
                                Configuration
                            </a>
                        </li>

                    </ng-container>
                </ul>
            </li>
        <li *ngIf="sessionServ.permAdmin && sessionServ.modeAdmin"
            role="menuitem" >
            <a href="#" [routerLink]="['/admin']">
<!--                <i class="fas fa-fw fa-cogs"></i>-->
                <fa-icon [icon]="faCogs" [fixedWidth]="true"></fa-icon>
                <span>Administration</span>

            </a>
        </li>


        <li #gestion_evenements
            *ngIf="sessionServ.modeAdmin"
            [ngClass]="{'menuitem-active':app.activeProfileItem === gestion_evenements}">
            <a href="#" (click)="onProfileItemClick($event,gestion_evenements);"
               [routerLink]="['/gestionEv']"
            >
<!--                <i class="fas fa-fw fa-hockey-puck"></i>-->
                <fa-icon [icon]="faHockeyPuck" [fixedWidth]="true"></fa-icon>
                <span class="topbar-item-name">Gestion activités</span>
            </a>
        </li>
        <li #gestion_sites
            *ngIf="sessionServ.modeAdmin"
            [ngClass]="{'menuitem-active':app.activeProfileItem === gestion_sites}">
            <a href="#" (click)="onProfileItemClick($event,gestion_sites);"
               [routerLink]="['/arenas']"
            >
<!--                <i class="fas fa-fw fa-globe"></i>-->
                <fa-icon [icon]="faGlobe" [fixedWidth]="true"></fa-icon>
                <span class="topbar-item-name">Gestion sites</span>
            </a>
        </li>
        <li #gestion_documents
            *ngIf="sessionServ.permChefAdmin"
            [ngClass]="{'menuitem-active':app.activeProfileItem === gestion_documents}">
            <a href="#" (click)="onProfileItemClick($event,gestion_documents);"
               [routerLink]="['/documents']"
            >
<!--                <i class="far fa-fw fa-file-pdf"></i>-->
                <fa-icon [icon]="faFile" [fixedWidth]="true"></fa-icon>
                <span class="topbar-item-name">Documents signés</span>
            </a>
        </li>
<!--        <li #gestion_doublons-->
<!--            *ngIf="sessionServ.modeAdmin && sessionServ.permAdmin && sessionServ.superhero"-->
<!--            [ngClass]="{'menuitem-active':app.activeProfileItem === gestion_doublons}">-->
<!--            <a href="#" (click)="onProfileItemClick($event,gestion_doublons);"-->
<!--               [routerLink]="['/doublons']"-->
<!--            >-->
<!--                <i class="fas fa-fw fa-users"></i>-->
<!--                <span class="topbar-item-name">Doublons</span>-->
<!--            </a>-->
<!--        </li>-->
        <li #entree_manuelle
            *ngIf="sessionServ.modeAdmin && sessionServ.permAdmin"
            [ngClass]="{'menuitem-active':app.activeProfileItem === entree_manuelle}">
            <a href="#" (click)="onProfileItemClick($event,entree_manuelle);"
               [routerLink]="['/manuel']"
            >
<!--                <i class="fas fa-fw fa-hand-paper"></i>-->
                <fa-icon [icon]="faHandPaper" [fixedWidth]="true"></fa-icon>
                <span class="topbar-item-name">Entrée manuelle</span>
            </a>
        </li>
        <li #rapports
            *ngIf="sessionServ.modeAdmin"
            [ngClass]="{'menuitem-active':app.activeProfileItem === rapports}">
            <a href="#" (click)="onProfileItemClick($event,rapports);"
               [routerLink]="['/rapports']"
            >
<!--                <i class="fas fa-fw fa-print"></i>-->
                <fa-icon [icon]="faPrint" [fixedWidth]="true"></fa-icon>
                <span class="topbar-item-name">Gestion de cas</span>
            </a>

        </li>


    </ng-template>
</div>

<p-dialog
        [(visible)]="showDialVersion"
        [modal]="true"
        (onShow)="nouvelleVersion = version"
>
    <ng-template pTemplate="header">Réglage du no de version sur le serveur</ng-template>
    <div class="m-3">
        <div class="my-3">No de version locale: {{version}}</div>
        No de version sur le serveur: {{pageServ.versionServeur}}
    </div>
    <div class="m-3">
        <input pInputText [(ngModel)]="nouvelleVersion" maxlength="10">
    </div>
    <ng-template pTemplate="footer">
        <div class="text-right">
            <button pButton label="Sauvegarder" icon="pi pi-check" (click)="sauvegarderVersion()"></button>
            <button class="ml-4" pButton label="Annuler" icon="pi pi-times" (click)="showDialVersion = false"></button>
        </div>
    </ng-template>
</p-dialog>
