import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../app.module';
import {FormService} from '../../services/form.service';
import {FormEvent, FormulairesState} from '../../store/reducers/formulaires.reducer';
import {Router} from '@angular/router';
import * as R from 'ramda';
import * as moment from 'moment';
import {GrowlService} from '../../services/growl.service';
import {interval} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {XhrService} from '../../services/xhr.service';
import {PageService} from '../../services/page.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-page-depot-formulaire',
  templateUrl: './page-depot-formulaire.component.html',
  styleUrls: ['./page-depot-formulaire.component.sass']
})
export class PageDepotFormulaireComponent implements OnInit, OnDestroy {
  
  
  
  public liste;
  public forms: FormulairesState;
  public events = [];
  public lieuxEventsOptions = [];
  public lieuxTousOptions = [];
  public lieuChoisi;
  public evChoisi;
  public heureListe;
  public filteredEvents = [];
  
  public siteNoEvent;
  public refreshing = false;
  
  public tropTard;
  
  // config
  public heuresDAvanceDepotPermis = 1.5;
  
  public subs = [];

  constructor(
    public store: Store<StoreInterface>,
    public formServ: FormService,
    public router: Router,
    public growl: GrowlService,
    public cd: ChangeDetectorRef,
    public session: SessionService,
    public xhr: XhrService,
    public pageServ: PageService
  ) {
    this.setTropTard();
    if (this.formServ.idsChoisisPourDepot.length === 0) {
      this.growl.error('Aucune sélection', 'Sélectionnez au moins une personne pour déposer son formulaire');
      this.router.navigate(['..']);
    }
  }

  ngOnInit(): void {
    this.subs.push(
      interval(20000).subscribe(() => {
        this.setTropTard();
        this.cd.detectChanges();
    }),
      this.store.select(createSelector(R.prop('formulaires'), R.identity)).subscribe(forms => {
        this.forms = forms as FormulairesState;
        this.liste = R.pipe(
          R.path(['ref', 'refMembres']),
          R.pick(this.formServ.idsChoisisPourDepot),
          R.values
        )(forms);
    
    
        this.events = R.pipe(
          R.path(['ref', 'events']),
          R.values,
          R.filter(ev => moment(ev.date).subtract(6, 'hour').twix(moment(ev.fin)).contains(moment())),
          R.map(ev => ({
            id: ev.id,
            type: this.typeDesc(ev.type),
            resp: this.nomResp(ev.responsable),
            descr: ev.descr,
            heure: ev.date,
            lieu: this.nomLieu(ev.lieu),
            idLieu:  ev.lieu,
            invisible: ev.invisible
          })),
          R.sortBy(R.prop('heure')),
        )(forms);
        
        const idsLieuxEvents = R.pipe(
          R.pluck('idLieu'),
          R.uniq
        )(this.events);
        
        this.lieuxTousOptions = R.pipe(
          R.path(['ref', 'formLieux']),
          R.values,
          R.sortBy(R.prop('ordre')),
          R.map(item => ({label: item.nom, value: item.id})),
          R.prepend({value: null, label: `---`})
        )(this.forms);
        
        this.lieuxEventsOptions = R.pipe(
          R.path(['ref', 'formLieux']),
          R.pick(idsLieuxEvents),
          R.values,
          R.sortBy(R.prop('ordre')),
          R.map(item => ({label: item.nom, value: item.id})),
          R.prepend({value: null, label: 'Choisissez un lieux'})
        )(this.forms);
      })
    );
  
  }
  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
  
  nomLieu(idLieu) {
    return R.pathOr('?', ['ref', 'formLieux', idLieu, 'nom'], this.forms);
  }
  
  nomResp(idMembre) {
    return R.pathOr('?', ['ref', 'refMembres', idMembre, 'nom'], this.forms);
  }
  
  typeDesc(id) {
    return R.pathOr('?', ['ref', 'typesEvents', id, 'desc'], this.forms);
  }
  
  setChoixEv() {
    this.filteredEvents = R.filter(R.allPass([R.propEq('idLieu', this.lieuChoisi), R.complement(R.prop('invisible'))]), this.events);
    
  }
  humanize(time) {
    return  moment.duration(moment(time).diff(moment())).humanize(true);
  }
  format(time) {
    return moment(time).format('H[h]mm');
  }
  futur(time) {
    return moment(time).isAfter(moment());
  }
  
  async deposer() {
    if (!this.evChoisi) {
      return;
    }
    if (this.evChoisi === -1 && !this.siteNoEvent) {
      this.growl.error('Choisir le site où vous déposez!');
      return;
    }
    const evChoisi = this.evChoisi === -1 ? null : this.evChoisi;
    const lieu = this.evChoisi === -1 ? this.siteNoEvent : null;
    const succes = await this.formServ.deposer({evChoisi, lieu});
    if (succes) {
      this.formServ.clrChoix();
      this.router.navigate(['/']);
    }
  }
  setTropTard() {
    this.tropTard = moment().add(this.heuresDAvanceDepotPermis, 'hour').format('YYYY-MM-DD HH:mm');
  }
  tropTardDisabled = (ev) => {
    return ev.heure > this.tropTard;
  }
  
  async refresh() {
    this.refreshing = true;
    const delay = interval(900).pipe(take(1)).toPromise();
    await this.pageServ.getInitData();
    this.setChoixEv();
    await delay;
    this.refreshing = false;
  }
  consoleLog(val: any) {
    console.log(val);
  }
}

