import {createReducer, on} from '@ngrx/store';
import * as R from 'ramda';
import {
  debugOff,
  debugOn,
  login,
  logOff,
  oublierEnvoiCode, setCourrielsModeTest, setDepotsInterdits,
  setIdVisiteur,
  setModeAdmin,
  setRefusLogins,
  setSel,
  setSessionId,
  toggleLocal,
  updateSession
} from '../actions/session.actions';


export interface SessionState {
  
  idVisiteur: string;
  nomVisiteur: string;
  courriel: string;
  anneeNaissance: number;
  idResp: string;
  nomResp: string;
  courrielCodeEnvoye: string;
  heureCodeEnvoye: string;
  heureCodeBloque: string;
  refuserLogins: boolean;
  courrielsModeTest: boolean;
  
  sel: string;
  sessionId: string;
  modeAdmin: boolean;
  showLocal: boolean;
  
  permCreerEv: boolean;
  permVerif: boolean;
  permAdmin: boolean;
  permChefAdmin: boolean;
  superhero: boolean;
  depotInterdit: boolean;
  depotInterditRaison: string;
  debug: boolean;
  interdictionDepots: number;
  dateReprise: string;
  messageInterdictionDepots: string;
}

export const sessionInitialState: SessionState = {
  idVisiteur: null, // id dans table 'responsables' où courriel/mdp ont servi au login
  nomVisiteur: null,
  courriel: null,
  anneeNaissance: null,
  idResp: null,
  nomResp: null,
  // donnees si login admin
  
  courrielCodeEnvoye: null,
  heureCodeEnvoye: null,
  heureCodeBloque: null,
  showLocal: false,
  refuserLogins: false,
  courrielsModeTest: false,
  
  sel: null,
  sessionId: null,
  modeAdmin: false,
  
  permCreerEv: false,
  permVerif: false,
  permAdmin: false,
  permChefAdmin: false,
  superhero: false,
  depotInterdit: false,
  depotInterditRaison: '',
  debug: false,
  interdictionDepots: 0,
  dateReprise: null,
  messageInterdictionDepots: ''
};

export const sessionEmptyLoginData = R.omit(['sel', 'sessionId'], sessionInitialState);

// tslint:disable-next-line:variable-name
const _sessionReducer = createReducer(
  sessionInitialState,
  
  on(login, (state, {
    idVisiteur,
    nomVisiteur,
    courriel,
    anneeNaissance,
    idResp,
    nomResp,
    permCreerEv,
    permVerif,
    permAdmin,
    permChefAdmin,
    superhero,
    depotInterdit,
    depotInterditRaison
    
  }) => {
    return {
      ...state,
      idVisiteur,
      nomVisiteur,
      courriel,
      anneeNaissance,
      idResp,
      nomResp,
      permCreerEv,
      permVerif,
      permAdmin,
      permChefAdmin,
      superhero,
      depotInterdit,
      depotInterditRaison,
      debug: state.debug && superhero
    };
  }),
  
  // tslint:disable-next-line:no-shadowed-variable
  on(logOff, (state: SessionState) => ({
      ...sessionInitialState,
      sessionId: state.sessionId,
      sel: state.sel,
      showLocal: state.showLocal,
      refuserLogins: state.refuserLogins,
      debug: false
    })
  ),
  // tslint:disable-next-line:no-shadowed-variable
  on(setSel, (state, {sel}) => ({
    ...state,
    sel
  })),
  // tslint:disable-next-line:no-shadowed-variable
  on(setSessionId, (state, {sessionId}) => ({
    ...state,
    sessionId
  })),
  // tslint:disable-next-line:no-shadowed-variable
  // on(confirmerEnvoiCode, (state, {courrielCodeEnvoye, heureCodeEnvoye}) => ({
  //   ...state,
  //   courrielCodeEnvoye,
  //   heureCodeEnvoye
  // })),
  // tslint:disable-next-line:no-shadowed-variable
  on(oublierEnvoiCode, state => ({...state, courrielCodeEnvoye: null, heureCodeEnvoye: null})),
  // tslint:disable-next-line:no-shadowed-variable
  
  on(setIdVisiteur, (state, {idVisiteur}) => ({...state, idVisiteur})),
  
  on(setModeAdmin, (state, {mode}) => ({...state, modeAdmin: mode})),
  
  on(updateSession, (state, obj) =>
    (
      
      {
        ...state,
        ...R.pick([
          'nomVisiteur',
          'courriel',
          'anneeNaissance',
          'permCreerEv',
          'permVerif',
          'permAdmin',
          'permChefAdmin',
          'superhero'
        ], obj),
        ...{debug: R.prop('superhero', obj) && state.debug}
      })),
  
  on(toggleLocal, state => ({...state, showLocal: !state.showLocal})),
  
  on(setRefusLogins, (state, {refuserLogins}) =>
    ({...state, refuserLogins})),
  on(debugOn, (state, {}) => ({...state, debug: true})),
  on(debugOff, (state, {}) => ({...state, debug: false})),
  on(setDepotsInterdits, (state, {interdictionDepots, dateReprise, messageInterdictionDepots}) =>
    ({...state, interdictionDepots, dateReprise, messageInterdictionDepots})
  ),
  on(setCourrielsModeTest, (state, {courrielsModeTest}) => ({...state, courrielsModeTest}))
);


// tslint:disable-next-line:no-shadowed-variable
export function sessionReducer(state, action) {
  return _sessionReducer(state, action);
}
