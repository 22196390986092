import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {StoreInterface} from '../app.module';
import {createSelector, Store} from '@ngrx/store';
import * as R from 'ramda';
import {GrowlService} from '../../services/growl.service';
import {XhrService} from '../../services/xhr.service';
import {Subscription} from 'rxjs';
import {CustomValidators} from '../../shared/CustomValidators';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.sass']
})
export class PageLoginComponent implements OnInit, OnDestroy {
  
  public isLocal = false;
  public refuserLogins = false;
  
  public form: FormGroup;
  public subs: Subscription[] = [];
  
  
  constructor(
    public router: Router,
    public fb: FormBuilder,
    public loginServ: LoginService,
    public store: Store<StoreInterface>,
    public growl: GrowlService,
    public xhr: XhrService
  ) {
  }
  
  ngOnInit(): void {
    this.form = this.fb.group({
      courriel: ['', [Validators.required, CustomValidators.validCourrielOrPseudo]],
      mdp: ['', [Validators.minLength(5)]]
    });
    this.subs.push(
      this.store.select(createSelector(R.prop('session'), R.pick(['idVisiteur', 'refuserLogins'])))
        .subscribe(({idVisiteur, refuserLogins}) => {
          if (idVisiteur) {
            this.router.navigate(['/']);
          }
          this.refuserLogins = refuserLogins;
        })
    );
    this.isLocal = this.xhr.local;
    
  }
  
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  
  
  retour() {
    this.router.navigate(['..']);
  }
  
  soumettre() {
    if (!this.form.valid) {
      return;
    }
    
    this.loginServ.login(this.form.get('courriel').value, this.form.get('mdp').value);
    
  }
  
  login(n, p) {
    this.form.get('courriel').setValue(n);
    this.form.get('mdp').setValue(p);
    this.soumettre();
  }
  filtreEnter(ev) {
    if (ev.which === 13) {
      this.soumettre();
    }
  }
}
