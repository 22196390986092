import { Injectable } from '@angular/core';
import {XhrService} from './xhr.service';
import {Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import {adminSet, adminUpdateMembre} from '../store/actions/admin.actions';
import {GrowlService} from './growl.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public initialized = false;
  
  constructor(
    public xhr: XhrService,
    public store: Store<StoreInterface>,
    public growl: GrowlService
  ) { }
  
  async init() {
    const data = await this.xhr.get('gestion', 'get_init_data_admin', {}, {noThrow: true});
    if (data.result) {
      this.store.dispatch(adminSet(data));
      this.initialized = true;
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async initialize(force = false) {
    if (this.initialized && !force) {
      return;
    }
    await this.init();
    
  }
  
  async changePerm(id, perm) {
    const data = await this.xhr.post('gestion', 'change_perm', {
      id_membre: id,
      perm
    }, {noThrow: true});
    if (data.result) {
      this.store.dispatch(adminUpdateMembre(data.update));
    } else {
      this.growl.xhr_error(data);
    }
  }
  
}
