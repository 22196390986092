import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {MessageModule} from 'primeng/message';

// Application Components
import {AppComponent} from './app.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppMegamenuComponent} from './app.megamenu.component';
import {AppProfileComponent} from './app.profile.component';
import {AppRightPanelComponent} from './app.rightpanel.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {BreadcrumbService} from './breadcrumb.service';
import {MenuService} from './app.menu.service';
import {PagePrincipaleComponent} from './page-principale/page-principale.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {sessionReducer, SessionState} from '../store/reducers/session.reducer';
import {LoginService} from '../services/login.service';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';
import {PageLoginComponent} from './page-login/page-login.component';
import {SessionService} from '../services/session.service';
import {PageService} from '../services/page.service';
import {refDataReducer, RefDataState} from '../store/reducers/refData.reducer';
import {XhrService} from '../services/xhr.service';
import {GrowlService} from '../services/growl.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {validationCourrielsReducer, ValidationCourriels} from '../store/reducers/validationCourriels.reducer';
import { PageLoggedInComponent } from './page-logged-in/page-logged-in.component';
import {dossiersLiesReducer, DossiersLiesState} from '../store/reducers/dossiersLies.reducer';
import {DossiersLiesService} from '../services/dossiers-lies.service';
import { EtatFormsComponent } from './components/etat-forms/etat-forms.component';
import {formulairesReducer, FormulairesState} from '../store/reducers/formulaires.reducer';
import { FormStatusComponent } from './components/form-status/form-status.component';
import { PageFormulaireComponent } from './page-formulaire/page-formulaire.component';
import {FormService} from '../services/form.service';
import { PageDepotFormulaireComponent } from './page-depot-formulaire/page-depot-formulaire.component';
import {gestionReducer, GestionState} from '../store/reducers/gestion.reducer';
import {EvenementsService} from '../services/evenements.service';
import {GestionService} from '../services/gestion.service';
import {AdminService} from '../services/admin.service';
import {adminReducer, AdminState} from '../store/reducers/admin.reducer';
import {RapportsService} from '../services/rapports.service';
import {EntreeManuelleService} from '../services/entree-manuelle.service';
import {SharedModule} from '../shared/shared-module/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RippleModule} from 'primeng/ripple';
import {StyleClassModule} from 'primeng/styleclass';
import {BadgeModule} from 'primeng/badge';
import { WrapperComponent } from './wrapper/wrapper.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {superAdminReducer} from '../store/reducers/superAdmin.reducer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export interface StoreInterface {
  session: SessionState;
  refData: RefDataState;
  validationCourriels: ValidationCourriels;
  dossiersLies: DossiersLiesState;
  formulaires: FormulairesState;
  gestion: GestionState;
  admin: AdminState;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['session', 'refData', 'superAdmin'],
    rehydrate: true
  })(reducer);
}


const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    FieldsetModule,
    FontAwesomeModule,
    MessageModule,
    RadioButtonModule,
    ToggleButtonModule,
    TranslateModule.forRoot({
      defaultLanguage: null,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true
    }),
    StoreModule.forRoot({
        session: sessionReducer,
        refData: refDataReducer,
        validationCourriels: validationCourrielsReducer,
        dossiersLies: dossiersLiesReducer,
        formulaires: formulairesReducer,
        gestion: gestionReducer,
        admin: adminReducer,
        superAdmin: superAdminReducer
      },
      {metaReducers}
    ),
    EffectsModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),// ************************************************** pas pour prod
    ProgressSpinnerModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    RippleModule,
    StyleClassModule,
    BadgeModule
  ],
  declarations: [
    AppBreadcrumbComponent,
    AppComponent,
    AppFooterComponent,
    AppMegamenuComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppProfileComponent,
    AppRightPanelComponent,
    AppTopBarComponent,
    EtatFormsComponent,
    FormStatusComponent,
    PageDepotFormulaireComponent,
    PageFormulaireComponent,
    PageLoggedInComponent,
    PageLoginComponent,
    PagePrincipaleComponent,
    WrapperComponent
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // CarService, CountryService, EventService, NodeService,
    BreadcrumbService, MenuService,
    TranslateService,
    LoginService,
    SessionService,
    PageService,
    XhrService,
    GrowlService,
    MessageService,
    DossiersLiesService,
    FormService,
    EvenementsService,
    GestionService,
    AdminService,
    RapportsService,
    EntreeManuelleService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],

  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {

}
