import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from '../services/session.service';
import {GrowlService} from '../services/growl.service';

@Injectable({
  providedIn: 'root'
})
export class Age16OuPlusGuard implements CanActivate {
  
  constructor(public session: SessionService, public growl: GrowlService) {
  
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.session.age16OuPlus) {
      this.growl.error('Formulaire inaccessible', 'Accessible pour membres ayant 16 ans ou plus à leur anniversaire de cette année')
    }
    return this.session.age16OuPlus;
  }
  
}
