import {createAction, props} from '@ngrx/store';
import {AdminState} from '../reducers/admin.reducer';
import {RefMembre} from '../reducers/dossiersLies.reducer';


export const adminReset = createAction('[Admin] reset');

export const adminSet = createAction('[admin] set all vals', props<AdminState>());

export const adminUpdateMembre = createAction('[admin] update membre', props<RefMembre>());

export const adminUpdateMembres = createAction('[admin] update plusieurs membres', props<{ liste: RefMembre[] }>());
