import {Injectable} from '@angular/core';
import {XhrService} from './xhr.service';
import {GrowlService} from './growl.service';
import * as R from 'ramda';
import {stripAccents} from '../shared/utils';
import {interval} from 'rxjs';
import {take} from 'rxjs/operators';

export interface ManMembre {
  idMembre: string;
  nomMembre: string;
  ageMembre: string;
  present: string;
  manuel: boolean;
  idDepot: string;
  highlight?: number;
}

export interface ManPresencesEv {
  idEv: string;
  descEv: string;
  date: string;
  membres: ManMembre[];
}

export interface ManEventsLieu {
  lieu: string;
  idLieu: string;
  liste: ManPresencesEv[];
}

export interface ManResp {
  idResp: string;
  nomResp: string;
  ageResp: number;
}
export interface ManEnfant {
  idEnfant: string;
  nomEnfant: string;
  ageEnfant: number;
}
export interface ManCandidat {
  id: string;
  nom: string;
  age: number;
  nbInst: number;
  enfants: ManEnfant[];
  parents: ManResp[];
}
interface TreeMenuItem {
  label: string;
  data?: (string|number)[];
  expandedIcon?: string;
  collapsedIcon?: string;
  icon?: string;
  children?: TreeMenuItem[]
  selectable?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EntreeManuelleService {
  
  dateChargee;
  data: ManEventsLieu[] = [];
  choixMembres: ManCandidat[] = [];
  menuLieux: TreeMenuItem[] = [];
  constructor(
    public xhr: XhrService,
    public growl: GrowlService
  ) {
  }
  
  async loadChoixActivites(date) {
    this.data = [];
    this.choixMembres = [];
    const data = await this.xhr.get('entree_manuelle', 'get_activites',
      {date}, {noThrow: true});
    
    if (data.result) {
      this.dateChargee = date;
      this.data = data.liste;
      this.menuLieux = [];
      for (let indLieu = 0, len = data.liste.length; indLieu < len; indLieu++) {
       
        const item: TreeMenuItem = {
          label: data.liste[indLieu].lieu,
          selectable: false,
          expandedIcon: 'pi pi-folder-open',
          collapsedIcon: 'pi pi-folder'
        };
        const liste: TreeMenuItem[] = [];
        for (let indChoix = 0, nbChoix = data.liste[indLieu].liste.length; indChoix < nbChoix; indChoix++) {
          liste.push({
            label: data.liste[indLieu].liste[indChoix].descEv,
            selectable: true,
            data: [indLieu, 'liste', indChoix]
          });
        }
        item.children = liste;
        this.menuLieux.push(item);
      }
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  }
  
  async loadChoixMembres(nom) {
    this.choixMembres = [];
    const data = await this.xhr.get('entree_manuelle', 'get_choix_membres', {
      nom
    }, {noThrow: true});
    if (data.result) {
      this.choixMembres = data.liste;
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  }
  
  async deposer_formulaires(idEvent, ids: string[]) {
    const data = await this.xhr.post('entree_manuelle', 'ajout_manuel',
      {idEvent, ids}, {noThrow:  true});
    if (data.result) {
      const indexLieu = R.findIndex(R.propEq('idLieu', data.idLieu), this.data);
      if (indexLieu < 0) {
        this.growl.error('Rechargez la page','Mise à jour faite mais impossible à afficher');
        return false;
      }
      const indexEvent = R.findIndex(R.propEq('idEv', data.idEvent), this.data[indexLieu].liste);
      if (indexEvent < 0) {
        this.growl.error('Rechargez la page','Mise à jour faite mais impossible à afficher');
        return false;
      }
      const lens = R.lensPath([indexLieu, 'liste', indexEvent, 'membres']);
      const highlightTimeLimit = Date.now() + 1999;
      this.data = R.over(lens, R.pipe(
        R.concat(R.map(R.assoc('highlight', highlightTimeLimit),data.updates)),
        R.sortBy(item => R.trim(stripAccents(item.nomMembre)))
      ), this.data);
      this.scheduleUnhighlight();
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  }
  
  scheduleUnhighlight() {
    interval(2000).pipe(take(1)).subscribe(() => {
      const time = Date.now();
      const adjust = [];
      this.data.forEach((dataLieu, indexLieu) => {
        dataLieu.liste.forEach((dataEv, indexEv) => {
          dataEv.membres.forEach((membre, indexMembre) => {
            if (membre.highlight && membre.highlight < time) {
              adjust.push(R.over(R.lensPath([indexLieu, 'liste', indexEv, 'membres', indexMembre]), R.assoc('highlight', 0)));
            }
          });
        });
      });
      
      if (adjust.length) {
        this.data = R.pipe(
          ...adjust
        )(this.data);
      }
    });
  }
  
  async changerPresence(idDepot) {
    const data = await this.xhr.post('entree_manuelle', 'changer_presence', {
      idDepot
    }, {noThrow: true});
    if (data.result) {
      
      const path = this.findPathDepot(data.idLieu, data.idEv, idDepot);
      if (!path) {
        this.growl.error('Rechargez la page');
        return false;
      }
      this.data = R.assocPath([...path, 'present'], data.present, this.data);
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  
  }
  
  findPath(idLieu, idEv = null, idMembre = null, all = false) {
    const indexLieu = R.findIndex(R.propEq('idLieu', idLieu), this.data);
    if (indexLieu < 0) {
      return false;
    }
    if (!idEv) {
      return [indexLieu];
    }
    const indexEv = R.findIndex(R.propEq('idEv', idEv), this.data[indexLieu].liste);
    if (indexEv < 0) {
      return false;
    }
    if (!idMembre) {
      return [indexLieu, 'liste', indexEv];
    }
    if (!all) {
      const indexMembre = R.findIndex(R.propEq('idMembre', idMembre), this.data[indexLieu].liste[indexEv].membres);
      if (indexMembre < 0) {
        return false;
      }
      return [indexLieu, 'liste', indexEv, 'membres', indexMembre];
    }
    const paths = [];
    const basePath = [indexLieu, 'liste', indexEv, 'membres'];
    R.path(basePath, this.data).forEach((val, ind) => {
      if (val.idMembre === idMembre) {
        paths.push([...basePath, ind]);
      }
    });
    return paths;
  }
  
  findPathDepot(idLieu, idEv, idDepot) {
    const path = this.findPath(idLieu, idEv);
    if (!path) {
      return false;
    }
    const indexDepot = R.findIndex(R.propEq('idDepot', idDepot), R.path([...path, 'membres'], this.data));
    if (indexDepot < 0) {
      return false;
    }
    return [...path, 'membres', indexDepot];
  }
  
  async retirer(idDepot) {
    const data = await this.xhr.post('entree_manuelle', 'retirer_depot', {idDepot}, {noThrow: true});
    if (data.result) {
      const path = this.findPathDepot(data.idLieu, data.idEv, idDepot);
      if (!path) {
        this.growl.error('rechargez la page');
        return false;
      }
      this.data = R.dissocPath(path, this.data);
      return true;
    }
    this.growl.xhr_error(data);
    return false;
  }
}
