import {Injectable} from '@angular/core';
import {XhrService} from './xhr.service';
import {GrowlService} from './growl.service';
import * as moment from 'moment';
import * as R from 'ramda';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import produce from 'immer';

export interface Contact {
  id: string;
  nom: string;
  age: number;
  courriel: string;
  cell: string;
}
export interface SoumissionsDepots {
  date: string;
  desc: string;
}
export interface Signalement {
  id: string; // membre
  nom: string;
  age: number;
  idInstance: string;
  date: string;
  verifiePar: string;
  verifieDate: string;
  nomVerif: string;
  suivi: string;
  regle: string;
  dateMesures: string;
  raisonMesures: string;
  msgMembreMesures: string;
  retraitMesures: string;
  regleParMesures: string;
  nomRegleParMesures: string;
  dateRegleMesures: string;
  idMesures: string;
  nbAutresmesures: number; // nombre de suivis de cas actifs non liés à ce formulaire
}

@Injectable({
  providedIn: 'root'
})
export class RapportsService {
  
  public dataSignalements: Signalement[] = [];
  
 
  public infoInstances: {
    [idInstance: string]: {
      refreshing: boolean;
      msgRefresh: string;
      obtenu: string;
      contacts: {
        self: Contact;
        parents?: Contact[];
        enfants?: Contact[],
        indirects?: Contact[]
      },
      suivi: string;
      nomRempliPar: string;
      ageRempliPar: number;
      soumissionsDepots: SoumissionsDepots[];
    }
  } = {};
  
  constructor(
    public xhr: XhrService,
    public growl: GrowlService,
    public store: Store<StoreInterface>
  ) {
    this.store.select(createSelector(R.prop('session'), R.prop('permAdmin'))).subscribe((admin) => {
      if (!admin) {
        this.reset();
      }
    });
  }
  
  async getDepotsDate(debut?, fin?) {
    if (!debut) {
      debut = moment().format('YYYY-MM-DD');
    }
    const queryParams: any = {debut};
    if (fin) {
      queryParams.fin = fin;
    }
    
    const data = await this.xhr.post('rapports', 'depots_date', queryParams, {noThrow: true});
    if (data.result) {
      return data.liste;
      
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async getFormProbl() {
    const data = await this.xhr.post('rapports', 'retraits_recents', {}, {noThrow: true});
    
    if (data.result) {
      this.dataSignalements = data.liste;
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  reset() {
    this.dataSignalements = [];
  }
  
  async getInfoSignalement(idInstance, forceRefresh = false) {
    
    if (!forceRefresh) {
      const infoInstance = R.prop(idInstance, this.infoInstances);
      if (infoInstance) {
        return infoInstance;
      }
    }
    this.infoInstances = R.pipe(
      R.assocPath([idInstance, 'msgRefresh'], 'rafraîchissement en cours...'),
      R.assocPath([idInstance, 'refreshing'], true)
    )(this.infoInstances);
    const data = await this.xhr.post('rapports', 'get_info_instance', {id_instance: idInstance}, {noThrow: true});
    if (data.result) {
      this.infoInstances = R.pipe(
        R.assocPath([idInstance, 'refreshing'], false),
        R.assocPath([idInstance, 'msgRefresh'], 'Données obtenues à ' + moment().format('HH[h]mm')),
        R.assocPath([idInstance, 'obtenu'], moment().format('YYYY-MM-DD HH:mm')),
        R.assocPath([idInstance, 'suivi'], data.suivi),
        R.assocPath([idInstance, 'contacts'], data.contacts),
        R.assocPath([idInstance, 'nomRempliPar'], data.nomRempliPar),
        R.assocPath([idInstance, 'ageRempliPar'], data.ageRempliPar),
        R.assocPath([idInstance, 'soumissionsDepots'], data.soumissionsDepots)
      )(this.infoInstances);
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  async toggleRegle(idInstance) {
    const data = await this.xhr.post('rapports', 'toggle_regle', {idInstance}, {noThrow: true});
    if (data.result) {
      // produce(this.infoInstances, draft => {
      //   draft[idInstance].regle = data.regle;
      // });
      const index = this.findIndex(idInstance);
      if (index > -1) {
        this.dataSignalements =
          R.adjust(index,
            R.mergeLeft(R.pick(['regle', 'verifiePar', 'nomVerif', 'verifieDate'], data))
          )
     
          (this.dataSignalements);
      }
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  findIndex(idInstance) {
    return R.findIndex(R.propEq('idInstance', idInstance), this.dataSignalements);
  }
  
  async sauvegardeSuivi(idInstance) {
    const index = this.findIndex(idInstance);
    if (index === -1) {
      this.growl.error('Introuvable');
      return;
    }
    const data = await this.xhr.post('rapports', 'sauvegarde_suivi',
      {idInstance, suivi: R.path([index, 'suivi'], this.dataSignalements)}, {noThrow: true});
    if (data.result) {
      return true;
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
  
  async creerSuiviCas(raison, msgMembre, retrait, idMembre, idInstance) {
    const data = await this.xhr.post('mesures', 'creer_mesure', {
      raison,
      msg_membre: msgMembre,
      retrait,
      id_membre: idMembre,
      id_instance: idInstance
    }, {noThrow: true});
    if (data.result) {
      const index = R.findIndex(R.allPass([R.propEq('id', idMembre), R.propEq('idInstance', idInstance)]))(this.dataSignalements);
      if (index > -1) {
        this.dataSignalements = R.adjust(index, R.mergeLeft(data.update), this.dataSignalements);
        this.growl.info('OK');
        return true;
      } else {
        this.growl.error('Problème de mise à jour: rechargez la page');
        return false;
      }
    } else {
      this.growl.xhr_error(data);
      return false;
    }
  }
}
