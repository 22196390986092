import {Component, OnDestroy, OnInit} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../app.module';
import * as R from 'ramda';
import {SessionState} from '../../store/reducers/session.reducer';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-page-logged-in',
  templateUrl: './page-logged-in.component.html',
  styleUrls: ['./page-logged-in.component.css']
})
export class PageLoggedInComponent implements OnInit, OnDestroy {
  
  public nom;
  public idVisiteur;
  public subs: Subscription[] = [];
  
  constructor(
    public store: Store<StoreInterface>,
    public router: Router,
    public loginServ: LoginService
  ) {
  }
  
  ngOnInit(): void {
    this.subs.push(
      this.store.select(createSelector(R.prop('session'), R.identity)).subscribe(
        (session: SessionState) => {
          this.nom = session.nomVisiteur;
          this.idVisiteur = session.idVisiteur;
          if (!this.idVisiteur) {
            this.router.navigate(['/login']);
          }
        }
      )
    );
  }
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  
  
  
  logout() {
    this.loginServ.logout();
  }
  
}
