import {createAction, props} from '@ngrx/store';
import {MembrePersonnifie} from '../reducers/superAdmin.reducer';

export const initSuperAdmin = createAction('[personnification] réinitialisation');

export const ajoutePersonnification = createAction('[personnification] ajoute à historique',
  props<{record: MembrePersonnifie}>());

export const retirePersonnification = createAction('[personnification] retirer un élément',
  props<{record: MembrePersonnifie}>());

