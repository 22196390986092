import {createAction, props} from '@ngrx/store';
import {StatutCourriel} from '../reducers/validationCourriels.reducer';



export const clearAllValidationsCourriels = createAction('[validationCourriel] clear all -> store');


export const clearSingleValidationCourriel = createAction('[validationCourriel] clear single -> store',
  props<{courriel: string}>());

export const updateValidationCourriel = createAction('[ValidationCourriel] update single -> store',
  props< StatutCourriel >());

export const updateMultiValidationsCourriels = createAction('[ValidationCourriel] update multi -> store',
  props<{ statuts: StatutCourriel[] }>());
