import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {XhrService} from '../services/xhr.service';
import * as R from 'ramda';
import {SessionService} from '../services/session.service';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from './app.module';
import {Subscription} from 'rxjs';
@Injectable()
@Component({
    selector: 'app-menu',
    template: `
		<ul class="layout-menu">
			<li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
		</ul>
    `
})
export class AppMenuComponent implements OnInit, OnDestroy {

    model: any[];
    public subs: Subscription[] = [];
    public idVisiteur;

    constructor(
      public app: AppComponent,
      public xhr: XhrService,
      public session: SessionService,
      public store: Store<StoreInterface>
    ) {}

    ngOnInit() {
        
        this.subs.push(
          this.store.select(createSelector(R.prop('session'), R.prop('idVisiteur')))
            .subscribe(idVisiteur => {
              this.idVisiteur = idVisiteur;
              this.buildMenu();
          })
        );
        
    }
    
    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
    }
    
    buildMenu() {
        this.model = [];
        if (this.xhr.local) {
            // this.setTestMenu();
        }
    
        const menu = [
        
            {
                label: 'Page principale',
                icon: 'pi pi-home',
                routerLink: ['/']
            },
            {
                label: 'Arénas',
                icon: 'pi pi-map-marker',
                routerLink: ['/arenas']
            },
            {
                label: 'Codes d\'éthique',
                icon: 'pi pi-cloud',
                routerLink: ['/code-ethique']
            },
            {
                label: 'Info sur le site',
                icon: 'pi pi-question-circle',
                routerLink: ['/info']
            }
            
        ];
        if (this.session.permVerif) {
            menu.push({
                label: 'Notes de versions',
                icon: 'pi pi-comment',
                routerLink: ['versions']
            })
        }
        if (this.xhr.local) {
            menu.push({
                label: 'Personnifier',
                icon: 'pi pi-user',
                routerLink: ['/personnifier']
            });
        }
        if (!this.session.idVisiteur) {
            menu.unshift(
              {
                  label: 'Ouvrir une session',
                  icon: 'pi pi-user',
                  routerLink: ['/login']
              }
            );
        }
    
    
        this.model = [...menu, ...this.model];
    
    }
   
    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

}
