import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {GrowlService} from '../services/growl.service';
import {SessionService} from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLectGuard implements CanActivate {
  
  constructor(public growl: GrowlService,
              public session: SessionService) {
    
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.session.permVerif) {
      this.growl.error('Non autorisé');
    }
    return true;
  }
  
}
