<div id="layout-megamenu" class="ui-g-12 layout-megamenu fadeInDown" [ngClass]="{'layout-megamenu-active': app.megaMenuActive,'layout-megamenu-dark': app.megaMenuMode !== 'light',
         'layout-megamenu-gradient':  app.megaMenuMode === 'gradient'}" (click)="app.onMegaMenuClick()">
    <div class="ui-g-12 ui-md-6 ui-lg-3 layout-megamenu-submenu">
        <h4>System Reports</h4>
        <ul>
            <li>
                <a href="#" (click)="logSession()">
                    <i class="fa fa-fw fa-th-list"></i>
                    <span>Session</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-server"></i>
                    <span>Back-up Server</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-users"></i>
                    <span>Conversions</span>
                </a>

            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-database"></i>
                    <span>Storage Logs</span>
                </a>

            </li>
        </ul>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-3 layout-megamenu-submenu">
        <h4>Finance Reports</h4>
        <ul>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-file-text-o"></i>
                    <span>Annual Reports</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-dollar"></i>
                    <span>Revenue Reports</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-user-o"></i>
                    <span>HR Reports</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-bar-chart"></i>
                    <span>Finance Margin</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-3 layout-megamenu-submenu">
        <h4>Settings</h4>
        <ul>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-envelope-o"></i>
                    <span>E-mail Notifications</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-mobile"></i>
                    <span>Sms Alerts</span>
                </a>
            </li>
            <li>
                <a href="#">
                    <i class="fa fa-fw fa-globe"></i>
                    <span>Site Tracking</span>
                </a>
            </li>
        </ul>
    </div>
    <div class="ui-g-12 ui-md-6 ui-lg-3 layout-megamenu-submenu">
        <div class="content-img">
            <img src="assets/layout/images/image-mega.png" alt="california-layout" />

            <div class="img-megamenu">
                <span class="ui-g-12 img-header">Covid</span>
                <span class="ui-g-12 img-subheader">Auto-évaluation</span>
            </div>

            <p-button label="Buy Now" type="button" class="alt-btn"></p-button>
        </div>
    </div>
</div>
