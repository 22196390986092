import {createReducer, on} from '@ngrx/store';
import {
  formulairesInit,
  formulairesSet,
  formulairesUpdateDepotsMembres,
  formulairesUpdateInstancesMembres,
  formulairesUpdateRef
} from '../actions/formulaires.actions';
import * as R from 'ramda';
import * as moment from 'moment';
import produce from 'immer';

export interface FormulairesState {

  dataMembres: {
    [idMembre: string]: {
      reponses?: {[idInstance: string]: [FormReponse]};
      instances: FormInstance[];
      depots: FormDepot[];
    }
  };
  ref: FormRef;
}

export interface FormRef {
  forms: {[id: string]: FormFormulaire};
  events: {[id: string]: FormEvent};
  typesEvents: {[id: string]: FormTypesEvent};
  formLieux: {[id: string]: FormLieux};
  dureeValidite: number; // minutes
  joursCharges: number;
  refMembres?: {[id: string]: FormRefMembre};
  heureCharge: string;
}

const objectPaths = [
  ['dataMembres'],
  ['dataMembres', null, 'reponses', null],
  ['ref', 'forms'],
  ['ref', 'events'],
  ['ref', 'typesEvents'],
  ['ref', 'formLieux'],
  ['ref', 'refMembres']
];
export const fixPaths = (paths: string[][], obj, replacements = [[[], {}]]) => {
  const search = R.pluck(0, replacements);
  const repl = R.pluck(1, replacements);
  let position = 0;
  while (position < paths.length) {
    const path = paths[position];
    // console.log('checking path', path);
    const positionNull = R.findIndex(R.equals(null), path);
    if (positionNull === -1) {
      position++;
      continue;
    } else if (positionNull === 0) {
      paths.splice(position, 1);
      continue;
    }
    const pathBeforeNull = path.slice(0, positionNull);
    const pathBeforeNullVal = R.path(pathBeforeNull, obj);
    const valAtNull = R.path(pathBeforeNullVal, obj);
    // console.log(pathBeforeNull);
    // console.log(pathBeforeNullVal);
    if (R.is(Object, pathBeforeNullVal) && !Array.isArray(pathBeforeNullVal)) {
      // console.log('is obj');
      const nullIndexRepl = R.pipe(
        R.keys,
        R.map(key => R.update(positionNull, key, path))
      )(pathBeforeNullVal);
      // console.log('null ind repl', nullIndexRepl);
      // console.log('path before', [...paths]);
      paths.splice(position, 1, ...nullIndexRepl);
      // console.log('path after', [...paths]);
      continue;
    } else {
      // console.log('pas un objet à path ', pathBeforeNull, pathBeforeNullVal);
      paths[position++] = pathBeforeNull;
      // paths.splice(position, 1);
    }
    
  }
  // console.log('=========', paths);
  for (const path of paths) {
      const val = R.path(path, obj);
      const indexRempl = R.findIndex(R.equals(val), search);
      if (indexRempl > -1) {
        obj = R.assocPath(path, repl[indexRempl], obj);
      }
  }
  
  return obj;
};

export interface FormFormulaire {
  id: string;
  desc: string;
}

export interface FormInstance {
  id: string;
  idFormulaire: string;
  idMembre: string;
  date: string;
  rempliPar: string;
  restriction: boolean;
}

export interface FormReponse {
  id: string;
  idInstance: string;
  question: string;
  reponse: any;
}

export interface FormEvent {
  id: string;
  ref: string;
  type: string;
  lieu: string;
  responsable: string;
  descr: string;
  date: string;
  fin: string;
  invisible: boolean;
  test: boolean;
}

export interface FormEventUpdate {
  id: string;
  ref?: string;
  type?: string;
  lieu?: string;
  responsable?: string;
  descr?: string;
  date?: string;
  fin?: string;
  invisible?: boolean;
  test?: boolean;
}


export const emptyFormEvent = {
  id: null,
  ref: null,
  type: null,
  lieu: null,
  responsable: null,
  descr: '',
  date: null,
  fin: null,
  invisible: false,
  test: false
};

export interface FormTypesEvent {
  id: string;
  desc: string;
  ordre: number;
}

export interface FormLieux {
  id: string;
  nom: string;
  adresse: string;
  quartier: string;
  tel: string;
  url: string;
  ordre: number;
}

export interface FormRefMembre {
  id: string;
  nom: string;
}


export interface FormDepot {
  id: string;
  date: string;
  lieu: string;
  evenement: string;
  idInstance: string;
}

export const formulairesInitialState: FormulairesState = {
  dataMembres: {},
  ref: {
    forms: {},
    events: {},
    typesEvents: {},
    formLieux: {},
    refMembres: {},
    dureeValidite: 0,
    joursCharges: 0,
    heureCharge: null
  }
};

// tslint:disable-next-line:variable-name
const _formulairesReducer = createReducer(
  formulairesInitialState,
  on(formulairesInit, (state: FormulairesState) => formulairesInitialState),
  on(formulairesSet, (state, {forms}) => {
    // return fixPaths(objectPaths, forms);
    return R.assocPath(['ref', 'heureCharge'], moment().format('YYYY-MM-DD HH:mm'), forms);
  }),
  on(formulairesUpdateInstancesMembres, (state, {update_instances, update_membres}) => {
    return produce(state, (draft: FormulairesState) => {
      for (const idMembre in update_instances) {
        if (!update_instances.hasOwnProperty(idMembre)) {
          continue;
        }
        draft.dataMembres[idMembre] = draft.dataMembres[idMembre] || {
          instances: [],
          depots: []
        };
        draft.dataMembres[idMembre].instances = update_instances[idMembre];
        if (update_membres) {
          draft.ref.refMembres = R.mergeRight(draft.ref.refMembres, update_membres);
        }
      }
    });
    
  }),
  on(formulairesUpdateDepotsMembres, (state, {update_depots, update_membres}) => {
    return produce(state, (draft: FormulairesState) => {
      // console.log('*********************************************************************', update_depots);
      for (const idMembre in update_depots) {
        if (!update_depots.hasOwnProperty(idMembre)) {
          
          continue;
        }
        // console.log('=======================================================================')
        draft.dataMembres[idMembre] = draft.dataMembres[idMembre] || {
          instances: [],
          depots: []
        };
        // console.log('UUUUUUUUUUpdate dep', idMembre, draft.dataMembres[idMembre]);
        draft.dataMembres[idMembre].depots = update_depots[idMembre];
        // console.log('avec', update_depots[idMembre]);
        // console.log('devient',  idMembre, draft.dataMembres[idMembre]);
        
        if (update_membres) {
          draft.ref.refMembres = R.mergeRight(draft.ref.refMembres, update_membres);
        }
      }
    });
    
  }),
  
  on(formulairesUpdateRef, (state, {ref}) => {
    return produce(state, draft => {
      draft.ref = R.mergeRight(draft.ref, ref);
    });
  })
  
);

// tslint:disable-next-line:no-shadowed-variable
export function formulairesReducer(state, action) {
  return _formulairesReducer(state, action);
}
