import {createReducer, createSelector, on} from '@ngrx/store';
import {initDossiersLies, resetDossiersLies} from '../actions/dossiersLies.actions';
import * as R from 'ramda';
import produce from 'immer';

export interface DossiersLiesState {
  idsResp: string[]; // ids de répondants additionnels acceptés pour joueurs de idParent
  idsRespDe: string[]; // ids de répondants dont idParent est répondant additionnel
  idParent: string;
  ref: ListeDossiers;
  mesures: MesureActive[];
}
export interface ListeDossiers {
  [idMembre: string]: RefMembre;
}

export interface LienEnfant {
  id: string;
  idMembre: string; // l'enfant (clef dans liste)
  parent: boolean;
  gardien: boolean;
  officiel: boolean;
  authPar: string; // créateur du lien
  idResp: string; // ici, il s'agit d'un propriétaire du dossier ou d'un resp supplémentaire
}

export interface MesureActive {
  idMembre: string;
  idMesure:string;
  message: string;
}

export interface RefMembre {
  id: string;
  prenom: string;
  nom: string;
  courriel: string;
  cell: string;
  anneeNaissance: number;
  creePar: string;
  motPasseExiste: boolean;
  typeDefaut: string;
  codesEth: string[];
  idResp?: string; // ici c'est le propriétaire du dossier
  permChefAdmin?: boolean;
  permAdmin?: boolean;
  permCreerEv?: boolean;
  permVerif?: boolean;
  superhero?: boolean;
  depotInterdit: boolean;
  depotInterditRaison: string;
  
}

export const DossiersLiesUtils = {
  idsEnfants: createSelector(R.prop('dossiersLies'), R.pipe(R.prop('liste'), R.keys))
};

export const dossiersLiesInitialState: DossiersLiesState = {
  idsResp: [],
  idsRespDe: [],
  idParent: null,
  ref: {},
  mesures: []
};

// tslint:disable-next-line:variable-name
export const dossiersLiesReducer_ = createReducer(
  dossiersLiesInitialState,
  on(resetDossiersLies, state => dossiersLiesInitialState),
  
  on(initDossiersLies, (state, {idsResp, idsRespDe, idParent, ref, mesures}) => {
    return {
      idsResp,
      idsRespDe,
      idParent,
      ref,
      mesures
    };
  }),
  
  
  
);


export function dossiersLiesReducer(state, action) {
  return dossiersLiesReducer_(state, action);
}
