import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {GrowlService} from '../services/growl.service';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import * as R from 'ramda';
import {DossiersLiesState} from '../store/reducers/dossiersLies.reducer';
import {PageService} from '../services/page.service';

@Injectable({
  providedIn: 'root'
})
export class ParentGuard implements CanActivate {
  
  public isParent = false;
  public msg = '';
  
  constructor(
    public store: Store<StoreInterface>,
    public growl: GrowlService,
    public pageServ: PageService
  ) {
    
    this.store.select(createSelector(R.prop('dossiersLies'), R.identity)).subscribe(
      (doss: DossiersLiesState) => {
        const idParent = doss.idParent;
        if (!idParent) {
          this.msg = 'Info usager non encore chargée';
          this.isParent = false;
          return;
        }
        this.isParent = !!R.pipe(
          R.prop('ref'),
          R.values,
          R.find(R.propEq('idResp', idParent))
        )(doss);
        if (!this.isParent) {
          this.msg = 'Réservé pour membre ayant enregistré au moins un enfant';
        }
      }
    )
  }
  displayErrorIfAny() {
    if (!this.isParent) {
      this.growl.error('Non autorisé', this.msg);
    }
  }
  complete(subject) {
    console.log('parent guard observable emits', this.isParent);
    subject.next(this.isParent);
    subject.complete();
    this.displayErrorIfAny();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.pageServ.initialized) {
      this.displayErrorIfAny();
      return this.isParent;
    }
    
    const subject = new Subject<boolean>();
    const obs = subject.asObservable();
    
    this.pageServ.initEmitter.subscribe(
      () => {
        this.complete(subject);
      }
    )
    return obs;
  }
  
}
