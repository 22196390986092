import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {GrowlService} from '../services/growl.service';
import {SessionService} from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class ChefAdminGuard implements CanActivate {
  
  constructor(
    public growl: GrowlService,
    public session: SessionService
  ) {
  
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.session.permChefAdmin) {
      this.growl.error('statut chef admin requis');
      return false;
    }
    return true;
  }
  
}
