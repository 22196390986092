<div [class.fill-page]="!strip" [class.show-local]="session.superhero"
     [ngClass]="styleClass"
     [class.animation-duration-500]="fadein !== null"
     [class.fadein]="fadein !== null"

>

    <div class="grid">
        <div class="col-1" *ngIf="pathRetour && !strip"><a [routerLink]="pathRetour"><i
                class="fas fa-arrow-left"></i> Retour</a>
        </div>

        <div class="col-{{pathRetour && !strip ? '10' : '12'}}" style="text-align: center">
            <h1 style="margin:0">
                <ng-content select="header"></ng-content>
            </h1>
            <ng-content select="sub-header"></ng-content>
        </div>

        <div *ngIf="pathRetour && !strip" class="col-1"></div>
    </div>
    <div class="menu" [class.wide]="wide" *ngIf="!noMenu">
        <ng-content select="app-page-std-menu">
        </ng-content>
    </div>
    <div *ngIf="pageServ.routerNavigation"
         class="flex justify-content-center scalein"
    >
        <i class="fas fa-spinner fa-spin text-3xl"
           (click)="pageServ.routerNavigation = false;"
        ></i>
    </div>

    <div class="content-wrapper"
         [class.wide]="wide"
         [class.opacity-40]="pageServ.routerNavigation"
         [class.pointer-events-none]="pageServ.routerNavigation"
    >
        <div class="info">
            <ng-content select="[info]"></ng-content>
        </div>
        <ng-content></ng-content>
    </div>
</div>

