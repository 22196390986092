import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { StoreInterface } from '../app/app.module';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root'
})
export class SelectorsService {

  public $sessionOuverte;
  public sessionOuverte;
  private permAdmin = false;
  private superhero = false;

  constructor(
    public store: Store<StoreInterface>
  ) {

  }

  is(type: string) {
    switch (type) {
      case 'admin':
        return this.permAdmin;
      case 'superhero':
        return this.superhero;
      case 'logged in':
        return this.sessionOuverte;
      default:
        return false;
    }
  }

  get(key, ...path) {
    const selector = createSelector(R.prop(key), R.path(path));
    let result: any = 'pending';
    this.store.pipe(select(selector), take(1)).subscribe(val => result = val);
    return result;
  }
}
