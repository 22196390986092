<app-page-std>
    <header>Identifiez-vous!</header>
    <div style="text-align: center">

        <div *ngIf="refuserLogins" class="access-restreint">
            <i class="fas fa-exclamation-triangle"></i> Attention! Accès restreint pour entretien.
        </div>
        <div [formGroup]="form" style="text-align: left; margin: 2em auto; max-width: 30em" class="ui-fluid">

            <div class="p-field">
                <label for="login_courriel" translate>Courriel ou pseudonyme</label>
                <input id="login_courriel" pInputText formControlName="courriel" size="60">
            </div>
            <div class="p-field">
                <label for="login_mdp" translate>Mot de passe</label>
                <input id="login_mdp"
                       type="password"
                       (keypress)="filtreEnter($event)"
                       pInputText formControlName="mdp" size="60">
            </div>

        </div>
        <div style="max-width: 30em; margin: 2em auto">
            <div style="text-align: center">
                <button pButton label="Ouvrir une session" icon="fas fa-check"
                        (click)="soumettre()"
                ></button>
            </div>

            <div style="margin-top: 2em;display: flex; flex-direction: row; justify-content: space-between">
                <a [routerLink]="['/inscription']">Je veux m'inscrire</a>
                <a [routerLink]="['/perdumdp']">J'ai perdu mon mot de passe</a>
            </div>
            <div *ngIf="isLocal" style="display: flex;flex-direction: row;justify-content: space-around; margin-top: 1rem; opacity: 25%">
                <a (click)="login('normaline', 'ppPP)')">login normal</a>
                <a (click)="login('lectrice', 'ppPP)')">login admin lecture</a>
                <a (click)="login('creee', 'ppPP)')">login admin creer ev</a>
                <a (click)="login('adminis', 'ppPP)')">login admin</a>
                <a (click)="login('chefadmin', 'ppPP)')">login chef admin</a>
                <a (click)="login('jk7GHGhjgjh', 'ihjgKJGhjk*&?%*&?')">hero</a>
            </div>
        </div>
    </div>

    <div style="margin: 2rem 0; color: indianred" *ngIf="false">
        Notez qu'un ou deux enregistrements ont été corrompus et on dû être effacés cette nuit. C'est un nouveau
        programme, et tout ne sera pas nécessairement parfait dès le départ! Si cela vous est arrivé, entrez
        à nouveau vos données, tout simplement. Désolé pour le contretemps!
    </div>

</app-page-std>

