import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import * as R from 'ramda';
import * as moment from 'moment';

import {SessionState} from '../store/reducers/session.reducer';
import {GrowlService} from '../services/growl.service';
@Injectable({
  providedIn: 'root'
})
export class AdulteSeulementGuard implements CanActivate {
  public adulteLoggedIn = false;
  
  constructor(
    public store: Store<StoreInterface>,
    public growl: GrowlService
  ) {
    this.store.select(createSelector(R.prop('session'), R.identity)).subscribe(
      (session: SessionState) => {
        const currentYear = moment().year();
        this.adulteLoggedIn =
          (!!session.idVisiteur
            && !!session.anneeNaissance
            && session.anneeNaissance < currentYear - 18);
      }
    );
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.adulteLoggedIn) {
      this.growl.error('Page inaccessible', 'Ouverte aux membres majeurs seulement')
    }
    return this.adulteLoggedIn;
  }
  
}
