import {RefMembre} from './dossiersLies.reducer';
import {createReducer, on} from '@ngrx/store';
import {adminReset, adminSet, adminUpdateMembre, adminUpdateMembres} from '../actions/admin.actions';
import * as R from 'ramda';
import produce from 'immer';
import {stripAccents} from '../../shared/utils';



export interface AdminState {
  membres: RefMembre[];
  refMembres: { [id: string]: number };
  
}

export const adminInitialState: AdminState = {
  membres: [],
  refMembres: {}
};

const rebuildRef = (membres: RefMembre[]): { [id: string]: number } => {
  return R.pipe(
    R.addIndex(R.map)(
      (obj, ind) => [obj.id, ind]
    ),
    R.fromPairs
  )(membres);
};

const rebuildSateRef = (state: AdminState) => {
  state.refMembres = rebuildRef(state.membres);
};

const sortMembres = (state: AdminState) => {
  state.membres = R.sortWith([
    R.ascend((rec) => stripAccents(rec.nom).toLowerCase()),
    R.ascend((rec) => stripAccents(rec.prenom).toLowerCase())
  ])(state.membres);
  rebuildSateRef(state);
  
};

// tslint:disable-next-line:variable-name
const _adminReducer = createReducer(
  adminInitialState,
  
  on(adminReset, (state) => ({...adminInitialState})),
  
  on(adminSet, (state, data: AdminState) => {
      const final = R.pick(R.keys(adminInitialState), {...data});
      sortMembres(final);
      return final;
    }
  ),
  
  on(adminUpdateMembre, (state,
                         data: RefMembre) =>
    produce(state, (draft) => {
      const id = data.id;
      const index = R.propOr(null, id, draft.refMembres);
      if (index === null) {
        draft.membres.push(data);
        sortMembres(draft);
      } else {
        const modifiedObj = state.membres[index];
        const updateRef = (modifiedObj.nom !== data.nom || modifiedObj.prenom !== data.prenom);
        draft.membres[index] = R.mergeRight(state.membres[index], data);
        if (updateRef) {
          sortMembres(draft);
        }
      }
    })),
  
  on(adminUpdateMembres, (state,
                          {liste}) =>
    produce(state, (draft) => {
      let mustSort = false;
      liste.forEach((data, ind) => {
        const id = data.id;
        const index = R.propOr(null, id, draft.refMembres);
        if (index === null) {
          draft.membres.push(data);
          mustSort = true;
        } else {
          const modifiedObj = state.membres[index];
          const updateRef = (modifiedObj.nom !== data.nom || modifiedObj.prenom !== data.prenom);
          draft.membres[index] = R.mergeRight(draft.membres[index], data);
          if (updateRef) {
            mustSort = true;
          }
        }
        
      });
      if (mustSort) {
        sortMembres(draft);
      }
    })),
);

// tslint:disable-next-line:no-shadowed-variable
export function adminReducer(state, action) {
  return _adminReducer(state, action);
}
