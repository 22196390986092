import { Injectable } from '@angular/core';
import {XhrService} from './xhr.service';
import { initDossiersLies} from '../store/actions/dossiersLies.actions';
import {Store} from '@ngrx/store';
import {StoreInterface} from '../app/app.module';
import {GrowlService} from './growl.service';
import {SpecDependant} from '../app/components/ajouter-dependant/ajouter-dependant.component';

@Injectable({
  providedIn: 'root'
})
export class DossiersLiesService {

  
  public modifCount = 0;
  
  public doublons: string[] = [];
  
  constructor(
    public xhr: XhrService,
    public store: Store<StoreInterface>,
    public growl: GrowlService
  ) {
  
  
  }
  
  // async effacer({idResp, idMembre, idNouveauResp}) {
  //
  //   // console.log('effacer', idResp, idMembre);
  //   const data = await this.xhr.post('gestion_dossiers_lies', 'retirer_lien', {
  //     id_jeune: idMembre,
  //     id_resp: idResp,
  //     id_nouveau_resp: idNouveauResp
  //   }, {noThrow: true});
  //
  //   if (data.result) {
  //     this.store.dispatch(retirerRespDossiersLies({
  //       idResp,
  //       idMembre
  //     }));
  //     this.growl.info('OK');
  //     this.modifCount++;
  //     return true;
  //   } else {
  //     this.growl.xhr_error(data);
  //     return false;
  //   }
  //
  // }
  //
  async ajouter_dependants(idResp, liste: SpecDependant[]) {
    this.doublons = [];
    const data = await this.xhr.post('gestion_membres', 'ajout_dependants', {
      id_membre: idResp,
      liste
    }, {noThrow: true});
    
    if (data.result) {
      this.growl.info('OK');
      this.store.dispatch(initDossiersLies(data.dossiers_lies));
      this.modifCount++;
      return true;
    } else {
      this.growl.xhr_error(data);
      if (data.doublons) {
        this.doublons = data.doublons;
      }
      return false;
    }
  }
  
  clearDoublons() {
    this.doublons = [];
  }
  
}
