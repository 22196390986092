import {createReducer, on} from '@ngrx/store';
import {ajoutePersonnification, initSuperAdmin, retirePersonnification} from '../actions/superAdmin.actions';
import produce from 'immer';
import * as R from 'ramda';

export interface SuperAdminState {
  personnificationsRecentes: MembrePersonnifie[]
}


export interface MembrePersonnifie {
  id: string;
  nom: string;
  age: string;
  courriel: string;
  cell: string;
}

export interface MembreLie extends MembrePersonnifie {
  role: string; // resp, partage, frere, enfant
  reciproque: boolean;
}

export const SuperAdminInitialState: SuperAdminState = {
  personnificationsRecentes: []
}

export const _superAdminReducer = createReducer(
  SuperAdminInitialState,
  on(initSuperAdmin, (state: SuperAdminState) => SuperAdminInitialState),
  on(ajoutePersonnification, (state: SuperAdminState, {record}) => produce(
    state, (draft: SuperAdminState) => {
      draft.personnificationsRecentes =
      R.pipe(
        R.reject(R.propEq('id', record.id)),
        R.append(record)
      )(draft.personnificationsRecentes);
      if (draft.personnificationsRecentes.length > 15) {
        draft.personnificationsRecentes = R.slice(draft.personnificationsRecentes, 0, 15);
      }
    }
  )),
  on(retirePersonnification, (state: SuperAdminState, {record}) => produce(state, (draft: SuperAdminState) => {
    draft.personnificationsRecentes = R.reject(R.propEq('id', record.id), draft.personnificationsRecentes);
    }
  ))
  
  
)

export function superAdminReducer(state, action) {
  return _superAdminReducer(state, action);
}
