import {createAction, props} from '@ngrx/store';
import {DossiersLiesState} from '../reducers/dossiersLies.reducer';


export const resetDossiersLies = createAction('[DossiersLies] initialiser');

export const initDossiersLies = createAction('[DossiersLies] initialiser au complet',
  props<DossiersLiesState>());

// export const ajoutRespDossiersLies = createAction('[DossiersLies] ajouter liens resp->joueurs',
//   props<{idsResp, idsRespDe, idParent, ref: ListeDossiers}>());

// export const retirerRespDossiersLies = createAction('[Dossiers lies] retirer lien responsable->jeune',
//   props<{idResp, idMembre}>());
