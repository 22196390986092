import {createAction, props} from '@ngrx/store';
import {FormDepot, FormInstance, FormRef, FormRefMembre, FormulairesState} from '../reducers/formulaires.reducer';


export const formulairesInit = createAction('[formulaires] init');

export const formulairesSet = createAction('[formulaires] set -> store',
  props<{forms: FormulairesState}>());

export const formulairesUpdateMembre = createAction('[formulaires] update membre',
  props<{instances: FormInstance[], refMembres: {[id: string]: FormRefMembre}}>());

export const formulairesUpdateInstancesMembres = createAction('[formulaires] update instances membre',
  props<{update_instances: {[id: string]: FormInstance[]}, update_membres?: {[id: string]: FormRefMembre}}>());

export const formulairesUpdateDepotsMembres = createAction('[formulaires] update depots membre',
  props<{update_depots: {[id: string]: FormDepot[]}, update_membres?: {[id: string]: FormRefMembre}}>());

export const formulairesUpdateRef = createAction('[formulaires] update ref',
  props<{ref: FormRef}>()
  );
