import {Component, OnDestroy, OnInit} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {StoreInterface} from '../../app.module';
import * as R from 'ramda';
import {DossiersLiesState, RefMembre} from '../../../store/reducers/dossiersLies.reducer';
import {FormRefMembre, FormulairesState} from '../../../store/reducers/formulaires.reducer';
import {SessionService} from '../../../services/session.service';
import {SessionState} from '../../../store/reducers/session.reducer';
import {PageService} from '../../../services/page.service';
import {Subscription} from 'rxjs';
import {FormService} from '../../../services/form.service';

export interface ExtendedRefMembre extends RefMembre {
  idResp: string;
  nomResp: string;
}

@Component({
  selector: 'app-etat-forms',
  templateUrl: './etat-forms.component.html',
  styleUrls: ['./etat-forms.component.sass']
})
export class EtatFormsComponent implements OnInit, OnDestroy {
  
  public dossiers: DossiersLiesState;
  public idMembreCharge;
  public formulaires: FormulairesState;
  public idVisiteur;
  
  public loaded;
  
  public dossierVisiteur: ExtendedRefMembre;
  public dossiersLies: ExtendedRefMembre[] = [];
  public idForm;
  public subs: Subscription[] = [];
  
  constructor(
    public store: Store<StoreInterface>,
    public session: SessionService,
    public pageServ: PageService,
    public formServ: FormService
  ) {
  }
  
  ngOnInit(): void {
    this.idForm = this.pageServ.idForm;
    
    this.subs.push(
      this.store.select(createSelector(R.prop('session'), R.identity)).subscribe(
        (sess: SessionState) => {
          this.idVisiteur = sess.idVisiteur;
          this.loaded = this.idMembreCharge === this.session.idVisiteur;
        }
      ),
      
      this.store.select(createSelector(R.prop('dossiersLies'), R.identity)).subscribe(
        (dossiers: DossiersLiesState) => {
          this.dossiers = dossiers;
          this.idMembreCharge = dossiers.idParent;
          this.loaded = this.idMembreCharge === this.session.idVisiteur;
        }
      ),
      this.store.select(createSelector(R.prop('formulaires'), R.identity)).subscribe(
        (form: FormulairesState) => {
          this.formulaires = form;
          this.dossierVisiteur = R.path(['ref', 'refMembres', this.idVisiteur], form);
          this.dossiersLies = R.pipe(
            R.prop(['dataMembres']),
            R.keys,
            R.without([this.idVisiteur]),
            R.pick(R.__, R.path(['ref', 'refMembres'], form)),
            R.values,
            R.map((doss: ExtendedRefMembre) => {
              const idResp = R.path(['ref', doss.id, 'idResp'])(this.dossiers) || '0';
              const nomResp = R.pipe(
                R.path(['ref', idResp]),
                R.pick(['prenom', 'nom']),
                R.values,
                R.join(' ')
              )(this.dossiers) || '<introuvable; rafraîchir page>';
              return R.mergeRight({idResp, nomResp}, doss)
            })
          )(form);
          
          
          
          // console.log(this.dossiersLies);
        }
      )
    );
  }
  
  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
  
  codesEth(idMembre) {
    return  R.path(['ref',idMembre,'codesEth'], this.dossiers);
    // console.log('dossiers', this.dossiers);
    // console.log('codes eth = ' + val + ' pour ' + idMembre);
    // return val;
  }
}
