<div class="layout-wrapper" (click)="onLayoutClick()"

     [ngClass]="{'layout-wrapper-overlay-sidebar': isOverlay(),
                 'layout-wrapper-slim-sidebar': isSlim(),
                 'layout-wrapper-horizontal-sidebar': isHorizontal(),
                 'layout-wrapper-overlay-sidebar-active': overlayMenuActive,
                 'layout-wrapper-sidebar-inactive': staticMenuDesktopInactive,
                 'layout-wrapper-sidebar-mobile-active': staticMenuMobileActive}">
    <app-topbar></app-topbar>

    <app-megamenu></app-megamenu>

    <div class="layout-sidebar" (click)="onMenuClick($event)"
         [ngClass]="{'layout-sidebar-dark': menuMode !== 'light',
         'layout-sidebar-gradient':  menuMode === 'gradient'}">
        <div class="sidebar-scroll-content">
            <app-inline-profile *ngIf="profileMode=='inline' && !isHorizontal()"></app-inline-profile>
            <app-menu></app-menu>
        </div>
    </div>

    <app-rightpanel></app-rightpanel>

    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-main-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>
</div>

<p-toast position="top-right"></p-toast>

