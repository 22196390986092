import { Injectable } from '@angular/core';
import {createAction, createSelector, Store} from '@ngrx/store';
import { StoreInterface } from '../app/app.module';

import * as R from 'ramda';
import * as moment from 'moment';
import {XhrService} from './xhr.service';
import {SessionState} from '../store/reducers/session.reducer';
import {setModeAdmin} from '../store/actions/session.actions';
import {gestionReset} from '../store/actions/gestion.actions';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  
  public loggedIn;
  public sess: SessionState;
  public nomVisiteur: string;
  public idVisiteur;
  public idResp;
  public nomResp;
  public modeAdmin = false;
  public superhero = false;
  public permVerif = false;
  public permCreerEv = false;
  public permAdmin;
  public permChefAdmin = false;
  public local = false;
  public debug = false;
  public anneeNaissance;
  public depotInterdit: boolean;
  public depotInterditRaison: string;
  
  public isAdulte = false;
  public age16OuPlus = false;

  constructor(
    public store: Store<StoreInterface>,
    public xhr: XhrService,
    public router: Router
  ) {
    
    this.store.select(createSelector(R.prop('session'), R.identity)).subscribe(
      (sess: SessionState) => {
        this.sess = sess;
        this.loggedIn = !!sess.idVisiteur;
        this.nomVisiteur = sess.nomVisiteur;
        this.nomResp = sess.nomResp;
        this.idResp = sess.idResp;
        this.superhero = sess.superhero;
        this.permChefAdmin = sess.permChefAdmin || this.superhero;
        this.permAdmin = this.permChefAdmin || sess.permAdmin;
        this.permCreerEv = this.permAdmin || sess.permCreerEv;
        this.permVerif= this.permCreerEv || sess.permVerif;
        this.idVisiteur = sess.idVisiteur;
        this.modeAdmin = sess.modeAdmin;
        this.debug = sess.debug;
        this.anneeNaissance = sess.anneeNaissance;
        this.isAdulte = this.anneeNaissance && moment().year() >= (this.anneeNaissance + 18);
        this.age16OuPlus = this.anneeNaissance && moment().year() >= (this.anneeNaissance + 16);
        this.depotInterdit = sess.depotInterdit;
        this.depotInterditRaison = sess.depotInterditRaison;
      }
      
      );
    this.local = this.xhr.local;

  }
  isLoggedIn() {
    return this.loggedIn;
  }
  
  async toggleAdminMode() {
    
    const data = await this.xhr.post('login_visiteur', 'toggle_mode_admin', {}, {noThrow:  true});
    if (data.result) {
      this.store.dispatch(setModeAdmin(data));
    }
  }
  
  toggleLocal() {
  
  }
  updateStatus() {
    if (!this.permVerif && this.modeAdmin) {
      this.toggleAdminMode();
      this.store.dispatch(gestionReset());
      this.router.navigate(['/']);
    }
  }
}
