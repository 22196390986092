import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {PageStdComponent} from '../page-std.component';
import {RouterModule} from '@angular/router';
import {LabelComponentComponent} from '../../app/components/label-component/label-component.component';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {DropdownModule} from 'primeng/dropdown';
import {TrimmedInput} from '../trim.directive';
import {DialogModule} from 'primeng/dialog';
import {MenuModule} from 'primeng/menu';
import {ToastModule} from 'primeng/toast';
import {MyFilterPipe} from '../../app/pipes/myFilter.pipe';
import {PanelModule} from 'primeng/panel';
import {CheckboxModule} from 'primeng/checkbox';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    PageStdComponent,
    LabelComponentComponent,
    TrimmedInput,
    MyFilterPipe
  ],
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    MenuModule,
    OverlayPanelModule,
    PanelModule,
    RouterModule,
    TooltipModule,
    OverlayPanelModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    ToastModule,
    CardModule,
    FontAwesomeModule
  ],
  exports: [
    ButtonModule,
    CardModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    MenuModule,
    OverlayPanelModule,
    PageStdComponent,
    PanelModule,
    LabelComponentComponent,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    ToastModule,
    TrimmedInput,
    MyFilterPipe
  ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule { }
