
<app-label-component label="etat-forms"></app-label-component>
<app-form-status *ngIf="dossierVisiteur" [idForm]="idForm" [dossier]="dossierVisiteur"
                 [codesEth]="codesEth(dossierVisiteur.id)">

</app-form-status>
<div style="margin: 1rem 0;font-weight: bold; font-size: larger">{{dossiersLies.length ? 'Dossiers liés' : 'Aucun dossier lié'}}</div>
<app-form-status *ngFor="let dossier of dossiersLies"
                 [idForm]="idForm"
                 [dossier]="dossier"
                 [codesEth]="codesEth(dossier.id)"></app-form-status>


<div style="text-align: center">
    Après avoir sélectionné ci-dessus les formulaires des participants à un événement,
    déposez-les à cet événement pour y confirmer votre présence
    <div style="margin-top: 1rem">
        <button icon="pi pi-clone" pButton pRipple
                (click)="formServ.clrIds()"
                [routerLink]="['/depot']"
                label="Déposer le ou les formulaires sélectionnés"></button>
    </div>
</div>









