/**
 * Created by micra_000 on 2016-07-03.
 */
import { AbstractControl, Validators, FormControl, ValidatorFn } from '@angular/forms';
import * as R from 'ramda';

export class CustomValidators {
  static noCheckCodePostal = false;
  
  static regexPseudo = /^[^ @][^@]{3,48}[^ @]$/;

  static validLen(min?: number, max?) {

    const toRet: ValidatorFn[] = [];

    if (min) {
      toRet.push(Validators.minLength(min));
    }
    if (max) {
      toRet.push(Validators.maxLength(max));
    }
    return toRet;

  }

  static validGenre(ctrl: AbstractControl) {
    if (!ctrl.value || ['M', 'F', 'm', 'f'].indexOf(ctrl.value) === -1) {
      return {genre_invalide: true};
    }
    const initVal = ctrl.value;
    const value = initVal.toUpperCase();
    if (initVal !== value) {
      ctrl.setValue(value);
    }
    return null;
  }

  static validCodePostal(val: AbstractControl) {
    const initVal = val.value;
    if (!val.value || CustomValidators.noCheckCodePostal) {
      CustomValidators.noCheckCodePostal = false;
      return null;
    }
    const value = val.value.toUpperCase();
    if (!/^[A-Z]\d[A-Z] ?\d[A-Z]\d$/.test(value)) {
      return {code_invalide: true};
    }
    const formattedValue = value.substr(0, 3) + ' ' + value.substr(-3);
    if (initVal !== formattedValue) {
      val.setValue(formattedValue);
    }

    return null;
  }

  static formatCodePostal(val: string) {
    if (!val) {
      return val;
    }
    val = val.toUpperCase();
    if (val.length === 6) {
      val = val.substr(0, 3) + ' ' + val.substr(3);
    }
    return val;
  }

  static validDate(val: AbstractControl) {
    // console.log('......validate date ', val.value);
    if (!val.value) {
      // console.log('OK');
      return null;
    }
    if (Date.parseExact(val.value, 'yyyy-MM-dd')) {
      // console.log('OK');
      return null;
    }
    // console.log('INVALID');
    return {date_invalide: true};

  }

  static validCourriel(ctrl: AbstractControl) {
    const val = (ctrl.value || '').trim();

    if (CustomValidators.validCourrielFn(val)) {
      return null;
    }
    return {courriel_invalide: true};
  }
  static validCourrielFn(courriel: string) {
    // tslint:disable-next-line:max-line-length
    return (!courriel || /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(courriel));
  
  }
  
  static validCourrielOrPseudo(ctrl: AbstractControl) {
    const val = (ctrl.value || '').trim();
    // ctrl.setValue(val, {emitEvent: false});
    if (R.test(/^[^@]{5,50}$/, val)) {
      // console.log('passe pseudo');
      return null;
    }
    // console.log('passe pas pseudo');
    if (!R.test(/@/g, val)) { // si pas de @, c'est un pseudo qui n'a pas passé premier test
      // console.log('pas un pseudo valable, mais pas courriel non plus')
      return {courriel_invalide: true};
    }
    if (CustomValidators.validCourrielFn(val)) {
      // console.log('courriel valable');
      return null;
    }
    // console.log('courriel non valable');
    return {courriel_invalide: true};
  }

  static validTel(ctrl: FormControl) {
    let val = ctrl.value;
    const initVal = val;
    if (!val) {
      return null;
    }
    val = val.replace(/[^0-9]/g, '');
    if (val.length < 10) {
      const result = {};
      result['Au moins 10 chiffres (' + val.length + ')'] = true;
      return result;
    }
    let value = `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
    if (val.length > 10) {
      value += ' #' + val.substring(10);
    }
    if (initVal !== value) {
      ctrl.setValue(value, {emitEvent: false});
    }

    return null;
  }

  static validLang(ctrl: FormControl) {
    let val = ctrl.value;
    if (!val) {
      return null;
    }
    val = val.toUpperCase();
    if (val !== 'FR' && val !== 'EN') {
      return {langue_inconnue: true};
    }
    return null;
  }

  static formatTel(val) {
    if (!val || val.length < 10) {
      return val;
    }
    val = val.replace(/[^0-9]/g, '');
    let value = `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
    if (val.length > 10) {
      value += ' #' + val.substring(10);
    }
    return value;
  }

  static formatTelCtrl(ctrl: FormControl) {
    const val = ctrl.value;
    const formattedVal = CustomValidators.formatTel(val);
    if (formattedVal === val) {
      return;
    }
    ctrl.setValue(formattedVal);
  }

  static rangeInt(min: number, max: number): (FormControl) => object {
    return (ctrl: FormControl) => {
      // console.log('test ', '[' + ctrl.value + ']; len = ' + ctrl.value.length);
      const val = ctrl.value + '';
      if (!/^([1-9]\d+|\d)/.test(val)) {
        // console.log('invalide', val);
        return {invalide: true};
      }
      const numVal = parseInt(val, 10);
      if (numVal < min) {
        // console.log('petit', numVal, '<', min);
        return {trop_petit: true};
      }
      if (numVal > max) {
        // console.log('grand',numVal, '>', max);
        return {trop_grand: true};
      }
      return null;
    };
  }

  static regex(regex: RegExp): (FormControl) => object {
    return (ctrl: FormControl) => {
      if (!regex.test(ctrl.value)) {
        return {format_invalide: true};
      }
      return null;
    };

  }

  
  
  static mdp(minLen: number, opt = false): (FormControl) => object {
    return (ctrl: FormControl) => {
      // const trimmed = R.trim(ctrl.value || '');
      // console.log('trimmed', trimmed);
      // ctrl.setValue(trimmed);
      // const val = trimmed;
      const val = ctrl.value;
      if (val.length === 0 && opt) {
        return null;
      }
      if (val.length < minLen) {
        return {tropCourt: true};
      }
      const erreur = {} as {[ind: string]: string};
      erreur.mdpNonValide = `Au moins ${minLen} car. incluant au moins une minuscule, une majuscule, un chiffre et un caractère spécial`;

      const nbMinuscules = val.replace(/[^a-z]/g, '').length;
      if (!nbMinuscules) {
        return erreur;
      }

      const nbMajuscules = val.replace(/[^A-Z]/g, '').length;
      if (!nbMajuscules) {
        return erreur;
      }

      const nbChiffres = val.replace(/[^0-9]/g, '').length;
      if (!nbChiffres) {
        return erreur;
      }

      const nbSpeciaux = val.replace(/[0-9a-zA-Z ]/g, '').length;
      if (!nbSpeciaux) {
        return erreur;
      }
      return null;
    };
  }

  static mdp2(opts: {
    minLen?: number;
    optionnel?: boolean;
    minus?: boolean;
    maj?: boolean;
    chiffre?: boolean;
    special?: boolean;
  }): (FormControl) => object {
    return (ctrl: FormControl) => {
      // const trimmed = R.trim(ctrl.value || '');
      // console.log('trimmed', trimmed);
      // ctrl.setValue(trimmed);
      // const val = trimmed;
      const minLen = opts.minLen || 5;
      const defaultTrue = R.defaultTo(true);
      const minus = defaultTrue(opts.minus);
      const maj = defaultTrue(opts.maj);
      const chiffre = defaultTrue(opts.chiffre);
      const special = defaultTrue(opts.special);
      
      const val = ctrl.value;
      if (val.length === 0 && opts.optionnel) {
        return null;
      }
      if (val.length < minLen) {
        return {tropCourt: true};
      }
      const erreur: {[ind: string]: string} = {};
      erreur.mdpNonValide = `Au moins ${minLen} car. incluant au moins une minuscule, une majuscule, un chiffre et un caractère spécial`;
  
      if (minus) {
        const nbMinuscules = val.replace(/[^a-z]/g, '').length;
        if (!nbMinuscules) {
          return erreur;
        }
      }
  
      if (maj) {
        const nbMajuscules = val.replace(/[^A-Z]/g, '').length;
        if (!nbMajuscules) {
          return erreur;
        }
      }
  
      if (chiffre) {
        const nbChiffres = val.replace(/[^0-9]/g, '').length;
        if (!nbChiffres) {
          return erreur;
        }
      } else {
      }
  
      if (special) {
        const nbSpeciaux = val.replace(/[0-9a-zA-Z ]/g, '').length;
        if (!nbSpeciaux) {
          return erreur;
        }
      } else {
      }
      return null;
    };
  }
}
