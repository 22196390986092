import {Component} from '@angular/core';
import {AppComponent} from './app.component';


@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html',
    styleUrls: ['./app.rightpanel.component.sass']
})
export class AppRightPanelComponent {

    constructor(public app: AppComponent) {
 
    }
}
