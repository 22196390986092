import {createAction, props} from '@ngrx/store';
import {Depot, User} from '../reducers/gestion.reducer';
import {FormEvent, FormEventUpdate} from '../reducers/formulaires.reducer';


export const gestionReset = createAction('[Gestion] reset');

export const gestionSet = createAction('[Gestion] set',
  props<{users: {[id: string]: User}, evenements: FormEvent[], debutEv: string, finEv: string, depots: Depot[]}>());

export const gestionSaveEvent = createAction('[Gestion] sauvegarder ev', props<{ data: FormEvent }>());
export const gestionUpdateEvent = createAction('[Gestion] maj ev', props<{ data: FormEventUpdate }>());
export const gestionEffaceEvenement = createAction('[Gestion] efface evenement', props<{id: string}>());

export const gestionConfirmerPresence = createAction('[Gestion] confirmer presence',
  props<{idDepot: string, presenceConf: string}>());

export const gestionDeplacerDepot = createAction('[Gestion] deplacer depot',
  props<{
  idDepot: string;
  presenceConf: string;
  idEvent: string;
  dateEvent: string;
  finEvent: string;
  typeEv: string;
  idResp: string;
}>());

