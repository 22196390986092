<div class="component-label text-pink-500 text-xs"
     [style.float]="noFloat ? 'none' : right ? 'right' : 'left'"
     *ngIf="session.debug"
     [pTooltip]="label"
     [class.cursor-pointer]="overlay"
     (click)="openOverlay($event)"

>
<!--    <i class="far fa-star"></i>-->
    <fa-icon [icon]="faStar"></fa-icon>
</div>

<p-overlayPanel #OverlayPanel>
    <ng-content></ng-content>
</p-overlayPanel>
