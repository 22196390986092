import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../services/session.service';
import {PageService} from '../services/page.service';

@Component({
  selector: 'app-page-std',
  templateUrl: './page-std.component.html',
  styleUrls: ['./page-std.component.sass']
})
export class PageStdComponent implements OnInit {
  
  @Input() pathRetour = ['/'];
  @Input() strip = false;
  @Input() wide = false;
  @Input() styleClass = ''
  @Input() fadein = null;
  @Input() noMenu = false;
  constructor(
    public session: SessionService,
    public pageServ: PageService
  ) { }

  ngOnInit(): void {
    // console.log('fadein', this.fadein === null ? 'null' : this.fadein === undefined ? 'undef' : typeof(this.fadein));
    
  }
  
}
