import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {GrowlService} from '../services/growl.service';
import {SessionService} from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(
    public session: SessionService,
    public growl: GrowlService
  ) {
  
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const retVal =  this.session.permAdmin;
    if (!retVal) {
      this.growl.error('Statut d\'administrateur requis');
    }
    return retVal;
  }
  
}
