import {createReducer, on} from '@ngrx/store';
import {initRefData, refDataEffaceLieu, refDataSaveLieu} from '../actions/refData.actions';
import produce from 'immer';
import * as R from 'ramda';
import {stripAccents2} from '../../shared/utils';


export interface RefDataState {
  initialized: boolean;
  typesMembres: TypeMembre[];
  lieux: Lieu[];
  typesEvents: TypeEvent[];
  deltaMinJeune: number;
  ageMaxJeune: number;
  ageMinFormSante: number;
}

export interface Lieu {
  id: string;
  nom: string;
  adresse: string;
  quartier: string;
  tel: string;
  url: string;
  regex: string;
}

export const emptyLieu: Lieu = {
  id: null,
  nom: '',
  adresse: null,
  quartier: null,
  tel: '',
  url: null,
  regex: ''
};

export interface TypeMembre {
  id: string;
  type: string;
  ordre: number;
}

export interface TypeEvent {
  id: string;
  desc: string;
  ordre: number;
}

export const lieuComparisonFn = R.pipe(
  R.prop('nom'),
  stripAccents2,
  R.toLower);
export const lieuSortFn = R.sortBy(lieuComparisonFn);

export const refDataInitialState: RefDataState = {
  initialized: false,
  typesMembres: [],
  lieux: [],
  typesEvents: [],
  deltaMinJeune: 0,
  ageMaxJeune: 0,
  ageMinFormSante: 0
};

// tslint:disable-next-line:variable-name
const _refDataReducer = createReducer(
  refDataInitialState,
  
  on(initRefData, (state, {typesMembres, lieux, typesEvents, deltaMinJeune, ageMaxJeune, ageMinFormSante}) => ({
      typesMembres,
      lieux,
      typesEvents,
      initialized: true,
      deltaMinJeune,
      ageMaxJeune,
      ageMinFormSante
    })
  ),
  
  on(refDataSaveLieu, (state, {data}) => {
    return produce(state, draft => {
      // console.log('recu reducer', data);
      data = R.pick(R.keys(emptyLieu), data);
      const index = R.findIndex(R.propEq('id', data.id), state.lieux);
      
      if (index !== -1) {
        const initialNom = R.path(['lieux', data.id, 'nom'], draft);
        draft.lieux[index] = data;
        if (initialNom !== data.nom) {
          draft.lieux = lieuSortFn(draft.lieux);
        }
      } else {
        const lastNom = R.pipe(
          R.prop('lieux'),
          R.last,
          R.ifElse(
            R.length,
            R.prop('nom'),
            R.always('')
          )
        )(draft);
        draft.lieux.push(data);
        if (lieuComparisonFn({nom: lastNom}) > lieuComparisonFn(data)) {
          draft.lieux = lieuSortFn(draft.lieux);
        }
      }
    });
  }),
  
  on(refDataEffaceLieu, (state, {id}) => {
    return produce(state, draft => {
      const index = R.pipe(
        R.prop('lieux'),
        R.findIndex(R.propEq('id', id))
      )(draft);
      if (index > -1) {
        draft.lieux = R.remove(index, 1, draft.lieux);
      }
    });
  })
);

// tslint:disable-next-line:no-shadowed-variable
export function refDataReducer(state, action) {
  return _refDataReducer(state, action);
}
