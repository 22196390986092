import {createAction, props} from '@ngrx/store';
import {Lieu, TypeEvent, TypeMembre} from '../reducers/refData.reducer';


export const initRefData = createAction('[refData] initialize -> store',
  props<{
    typesMembres: TypeMembre[],
    lieux: Lieu[],
    typesEvents: TypeEvent[],
    deltaMinJeune: number,
    ageMaxJeune: number,
    ageMinFormSante: number

  }>());

export const refDataSaveLieu = createAction('[refData] save lieu', props<{data: Lieu}>());

export const refDataEffaceLieu = createAction('[refData] efface lieu', props<{id: string}>());
