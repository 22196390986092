<div class="layout-footer ui-g-12">
    <div class="ui-g">
        <div class="ui-g-12 ui-md-6 footer-left">
            <h3>Braves du Nord de Montréal</h3>
            <p>Confirmation d'état de santé avant activité</p>
        </div>
        <div class="ui-g-12 ui-md-6 footer-right">
            <a href="#">
                <i class="fa fa-twitter"></i>
            </a>
            <a href="#">
                <i class="fa fa-facebook"></i>
            </a>
            <a href="#">
                <i class="fa fa-github"></i>
            </a>
        </div>
    </div>
</div>
