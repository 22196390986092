import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import {SessionService} from '../../../services/session.service';


@Component({
  selector: 'app-label-component',
  templateUrl: './label-component.component.html',
  styleUrls: ['./label-component.component.css']
})
export class LabelComponentComponent implements OnInit {

  @Input() label: string;
  @Input() right: boolean;
  @Input() noFloat: boolean;
  @Input() overlay: boolean;
  
  @ViewChild('OverlayPanel') overlayPanel;
  
  faStar = faStar;

  constructor(
    public session: SessionService
  ) { }

  ngOnInit(): void {
  }

  openOverlay(ev) {
    if (this.overlay) {
      this.overlayPanel.show(ev);
    }
  }
}
