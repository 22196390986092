import {createAction, props} from '@ngrx/store';


export const login = createAction('[login] login visiteur -> store',
  props<{
    idVisiteur: string;
    nomVisiteur: string;
    courriel: string;
    anneeNaissance: number;
    idResp: string;
    nomResp: string;
    permCreerEv: boolean;
    permVerif: boolean;
    permAdmin: boolean;
    permChefAdmin: boolean;
    superhero: boolean;
    depotInterdit: boolean;
    depotInterditRaison: string;
  }>());


export const logOff = createAction('[login] log off');

export const setIdVisiteur = createAction('[login] set idVisiteur', props<{ idVisiteur: string }>());

export const setSel = createAction('[login] set sel', props<{ sel: string }>());
export const setSessionId = createAction('[login] set sessionId', props<{ sessionId: string }>());

export const confirmerEnvoiCode = createAction('[login] confirmer code envoye',
  props<{ courrielCodeEnvoye: string, heureCodeEnvoye: string }>());
export const oublierEnvoiCode = createAction('[login mdp oublie] rend possible envoi nouveau code si serveur permet ->store');

export const setModeAdmin = createAction('[session] set mode admin', props<{ mode: boolean }>());

export const updateSession = createAction('[session] update', props<{
  nomVisiteur?: string;
  courriel?: string;
  anneeNaissance?: number;
  idResp?: string;
  nomResp?: string;
  permCreerEv?: boolean;
  permVerif?: boolean;
  permAdmin?: boolean;
  permChefAdmin?: boolean;
  superhero?: boolean;
}>());

export const toggleLocal = createAction('[session] toggle mode local');

export const setRefusLogins = createAction('[session] set refus login', props<{refuserLogins: boolean}>());

export const debugOn = createAction('[session] debug on ');
export const debugOff = createAction('[session] debug off ');
export const setDepotsInterdits = createAction('[session] set_depots_interdits', props<{
  interdictionDepots: number;
  dateReprise: string;
  messageInterdictionDepots: string;
}>());
export const setCourrielsModeTest = createAction('[session] regler mode test', props<{courrielsModeTest}>());
